import { defaultPackageBlockData, PackageBlockTemplate } from '../../components/package-block/c-package-block.template'
import { defaultPriceData, PriceTemplate, getACMPriceLabel } from '../../components/price/c-price.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'

/**
 * The PromotedPriceData contains all data needed to hydrate a PromotedPrice view
 *
 * @typedef  {Object}           PromotedPriceResultData
 *
 * @property {PriceData}        price                       - Price data
 * @property {String}           skiPassPublicPriceText      - Ski pass public price text
 * @property {String}           buttonText                  - Promoted price text button
 * @property {PackageBlockData} packageBlock                - package data
 * @property {String}           [acmUrl]                    - ACM url where to request info to be displayed
 * @property {String}           [acmModalId]                - Modal ID where the ACM info will be shown at
 * @property {Boolean}          [acmHideInfoIcon]           - Hide the ACM info icon (an 'i' in an circle).
 * @property {Object}           [yieldCampaignLogo]         - Yield campaign logo
 * @property {String}           [yieldCampaignLogo.src]     - Yield campaign logo > src
 * @property {Boolean}          [hasUiTest]                 - Flag to indicate if this component is subjected to a UI test.
 * @property {String}           [rawDepartureDate]          - The package departure date, expressed as machine readable string.
 * @property {String}           [mandatoryExtraCostsText]   - The mandatory extra costs text, to be shown with the price.
 * @property {String}           [staticText]                - Some optional static text to be shown along the price.
 */
export const defaultPromotedPriceResultData = {
  price: {},
  skiPassPublicPriceText: '',
  buttonText: '',
  packageBlock: {},
  acmUrl: '',
  acmModalId: '',
  acmHideInfoIcon: true,
  yieldCampaignLogo: {},
  hasUiTest: false,
  rawDepartureDate: '',
  mandatoryExtraCostsText: '',
  staticText: ''
}

export const PromotedPriceTemplate = (d) => {
  d = { ...defaultPromotedPriceResultData, ...d }

  return `
    <div class="w-promoted-price__main">
      <div class="w-promoted-price__info-wrapper">
        ${d.yieldCampaignLogo?.src ? `<img class="w-promoted-price__yield" src="${d.yieldCampaignLogo.src}">` : ''}
        ${PackageBlockTemplate({
          ...defaultPackageBlockData,
          ...d.packageBlock,
          rawDepartureDate: d.rawDepartureDate,
          outboundDepartureAirportName: d.outboundDepartureAirportName
        })}
      </div>
      ${PriceTemplate({
        ...defaultPriceData,
        ...d.price,
        publicPriceDetail: d.skiPassPublicPriceText,
        hasUiTest: d.hasUiTest,
        acmHideInfoIcon: !!d.mandatoryExtraCostsText,
        label: d.scratchPriceDescription,
        label2: d.mandatoryExtraCostsText && d.price.acmUrl && d.price.acmModalId
          ? getACMPriceLabel({ ...d, text: d.mandatoryExtraCostsText, staticText: d.staticText, acmUrl: d.price.acmUrl, modalId: d.price.acmModalId })
          : d.staticText,
        labelGrayed: true
      })}
      ${BtnTemplate({
        variant: 'flow',
        block: true,
        text: d.buttonText,
        extraClasses: `w-promoted-price__btn ${d.hasUiTest ? 'qa-promoted-price-click-trigger' : ''}`
      })}
    </div>
  `
}
