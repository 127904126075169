export const widgetApi = 'w-payment'

export const widgetQueries = {
  paymentContainer: `[data-js-component="${widgetApi}__container"]`,
  paymentMethodsList: `[data-${widgetApi}__payment-methods-list]`,
  choiceListsPaymentMethods: `[data-${widgetApi}__payment-methods-choice-list]`, // to be deleted
  choiceListsPaymentOptions: `[data-${widgetApi}__payment-options-choice-list]`,
  choiceListApi: 'c-choice-list',
  radioButtonExtraContent: '[data-c-radio-button__extra-content]',
  paymentOptionsContainer: `[data-js-component="${widgetApi}__options-container"]`,
  paymentOptionsList: `[data-js-component="${widgetApi}__options-list"]`,
  paymentVoucherOption: `[data-js-component="${widgetApi}__voucher_option"]`,
  adyenPaymentProvider: `[data-js-component="${widgetApi}__adyen"]`,
  voucherRedeemer: `[data-${widgetApi}__voucher-redeemer]`,
  buttonApi: 'c-btn',
  buttonSubmit: `[data-${widgetApi}__payment-options-submit-button]`,
  buttonConfirmBooking: `[data-${widgetApi}__confirm-booking-button]`,
  paymentForm: `[data-js-component="${widgetApi}__form"]`,
  paymentFormHidden: `[data-js-component="${widgetApi}__form-hidden"]`,
  paymentAlert: `[data-js-component="${widgetApi}__alert"]`,
  confirmButtonContainer: `[data-js-component="${widgetApi}__confirm-button"]`,
  offlineMessages: `[data-js-component="${widgetApi}__offline-messages"]`,
  paymentOptionsTitle: `[data-${widgetApi}__options-title]`,
  voucherCustomRedeemer: `[data-${widgetApi}__payment-voucher-custom-redeemer]`,
  voucherOptionsChoiceList: `[data-${widgetApi}__voucher-options-choice-list]`,
  voucherOptionsAmountInput: `[data-${widgetApi}__voucher-options-amount-input]`,
  voucherOptionsAmountInputElement: `[data-${widgetApi}__voucher-options-amount-input-element]`,
  voucherOptionsSubmitButton: `[data-${widgetApi}__voucher-options-submit-button]`,
  voucherOptionsErrorMessageContainer: `[data-${widgetApi}__voucher-otpions-error-message-container]`,
  voucher: `[data-${widgetApi}__voucher]`,
  voucherAddLink: `[data-${widgetApi}__voucher-add-link]`,
  voucherOptionsCancelButton: `[data-${widgetApi}__voucher-options-cancel-button]`,
  voucherOptionsExpiredButton: `[data-${widgetApi}__voucher-options-expired-button]`,
  configurationElement: `[data-${widgetApi}__configuration]`,
  paymentMethodLoader: `.${widgetApi}__payment-method-loader`,
  partialAmountInputElement: `[data-${widgetApi}__partial-amount-input-element]`,
  partialAmountInput: `[data-${widgetApi}__partial-amount-input]`,
  partialRemainingBalanceText: `[data-${widgetApi}__partial-remaining-balance]`,
  partialTotalToPayText: `[data-${widgetApi}__partial-total-to-pay]`
}

export const attr = {
  paymentStatus: 'paymentStatus',
  paymentStatusFromUrl: 'payment',
  paymentFullText: 'paymentFullText',
  paymentDepositText: 'paymentDepositText',
  bookingNumberFromServer: 'bookingNumber',
  bookingNumFromServer: 'booknum',
  groupUserIdFromServer: 'groupUserId',
  fullPaymentAmount: 'fullPaymentAmount',
  downPaymentAmount: 'downPaymentAmount',
  optionsUrl: 'optionsUrl',
  createParametersUrl: 'createParametersUrl',
  paymentRedirectUrl: 'paymentRedirectUrl',
  checkVoucherUrl: 'checkVoucherUrl',
  checkVoucherMethod: 'checkVoucherMethod',
  applyVoucherUrl: 'applyVoucherUrl',
  enablePartialRedeem: 'data-enable-partial-redeem',
  track: 'data-track',
  voucherOptionsFullButtonText: `data-${widgetApi}__voucher-options-full-button-text`,
  voucherOptionsFullButtonCheckingText: `data-${widgetApi}__voucher-options-full-button-checking-text`,
  voucherOptionsPartialButtonText: `data-${widgetApi}__voucher-options-partial-button-text`,
  voucherOptionsPartialButtonCheckingText: `data-${widgetApi}__voucher-options-partial-button-checking-text`,
  isPartialVoucherPaymentEnabled: `data-${widgetApi}__is-partial-voucher-payment-enabled`,
  initiatePaymentUrl: 'initiatePaymentUrl',
  submitAdditionalDetailsUrl: 'submitAdditionalDetailsUrl'
}

export const configOptions = {
  homeUrl: 'homeUrl',
  successIconUrl: 'successIconUrl',
  optionsUrl: 'getOptionsUrl',
  createParametersUrl: 'createParametersUrl',
  initiatePaymentUrl: 'initiatePaymentUrl',
  submitAdditionalDetailsUrl: 'submitAdditionalDetailsUrl',
  checkVoucherUrl: 'checkVoucherUrl',
  checkVoucherMethod: 'checkVoucherMethod',
  applyVoucherUrl: 'applyVoucherUrl'
}

export const voucherTypes = {
  FULL_REDEEM: 'FULL_VOUCHER',
  PARTIAL_REDEEM: 'PARTIAL_VOUCHER'
}

export const paymentTypes = {
  DEPOSIT: 'deposit',
  FULL: 'full',
  PARTIAL: 'partial'
}

export const transactionTypes = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  VOUCHER: 'voucher'
}

export const paymentProviders = {
  OGONE: 'Ogone',
  BUCKAROO: 'Buckaroo',
  ADYEN: 'Adyen'
}

export const paymentStatus = {
  ACCEPTED: 'ok',
  DECLINE: 'decline',
  EXCEPTION: 'exception',
  CANCEL: 'cancel',
  PENDING: 'pending'
}

export const confettiLength = 50

export const classNames = {
  hidden: 'is-hidden'
}
