import './w-payment-iframe-resize'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { PaymentOptionsContainerTemplate } from './w-payment-options.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import { PaymentFormTemplate } from './w-payment-params.template'
import { PaymentAlertTemplate } from './w-payment-alert.template'
import { apiCaller } from '../../../js/helpers/api-caller'
import { ChoiceListTemplate, defaultChoiceListData } from '../../components/choice-list/c-choice-list.template'
import {
  attr,
  widgetQueries,
  widgetApi,
  paymentTypes,
  paymentProviders,
  transactionTypes,
  configOptions,
  confettiLength,
  classNames,
  paymentStatus
} from './payment-constants'
import VoucherOptions from './vouchers/voucher-options'
import VoucherRedeemer from './vouchers/voucher-redeemer'
import VoucherSelector from './vouchers/voucher-selector'
import registeredEvents from '../../../js/helpers/registered-events'
import { paymentEvents, bookingStepsEvents } from '../../../js/document/event-types'
import AdyenImplementation from './adyen/adyen'
import Partial from './partial/partial'
import { payment } from './event-types'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'

/**
 * Payment widget
 */
export default class Payment extends BookingBox {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   * @param {Object} [options={}]   - Options
   */
  constructor (element, options = {}) {
    super(element)
    this.element = element
    this.isBookingNumberFromServer = false
    this.locales = this.getLocales()
    this.track = this.element.hasAttribute(attr.track) ? this.element.attributes[attr.track].value : null

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.track }
    })

    this._initAttributesFromUrl()

    if (this.bookingNumber) {
      this.isBookingNumberFromServer = true
      this._updateWidgetData(this.bookingNumber)
    }
  }

  isDeferredAndResolvedByItself () {
    return true
  }

  async beforeNextStep () {
    return super.beforeNextStep()
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this.bookingNumber = this._getBookingIdentifier()
    if (this.bookingNumber) {
      await this._updateWidgetData(this.bookingNumber)
    }
    return super.handleFetched(data.response)
  }

  async _updateWidgetData (bookingNumber) {
    this._init()
    this._initConfigurations()

    this.isPartlyPaid = this.data && this.data.payment && this.data.payment.isPartlyPaid

    if (!this.isPartlyPaid && ![paymentStatus.ACCEPTED, paymentStatus.PENDING].includes(this.paymentStatus)) {
      const componentId = this.componentId
      let urlParams = `?bookingNumber=${bookingNumber}&contextitemid=${componentId}`
      if (this.groupUserId) {
        urlParams = urlParams + `&groupUserId=${this.groupUserId}`
      }
      if (this.fullPaymentAmount && this.downPaymentAmount) {
        urlParams = urlParams + `&fullPaymentAmount=${this.fullPaymentAmount}&downPaymentAmount=${this.downPaymentAmount}`
      }      const result = await apiCaller(`${this.configurations.optionsUrl}${urlParams}`)
      if (result.success) {
        this.apiData = result.response
        if (this.apiData && this.apiData.paymentMethods && this.apiData.paymentMethods.length > 0) {
          if (this._checkIfHasMandatoryPayment() && this._checkIfIsRebooking() && this.apiData && this.apiData.downPaymentAlreadyCovered) {
            this._showDownPaymentAlreadyCovered()
          } else {
            if ([paymentStatus.EXCEPTION, paymentStatus.DECLINE, paymentStatus.CANCEL].includes(this.paymentStatus)) {
              this._showPaymentError()
            }
            if (!this.paymentOptions) {
              this.paymentOptions = []
            }
            this.paymentProvider = this.apiData.paymentProvider || null
            this.paymentOptions[this.componentId] = this.apiData.paymentMethods
            this._showPaymentContainer(true)
          }
        } else {
          this._showPaymentFullyPaid()
        }
      } else {
        flush(this.paymentContainer)
        this._showPaymentError()
        this.enableUI()
      }
    } else if (this.isPartlyPaid) {
      this._showPaymentPartlyPaid()
    } else if ([paymentStatus.ACCEPTED].includes(this.paymentStatus)) {
      this._showPaymentSuccess()
      this.enableUI()
    } else if ([paymentStatus.PENDING].includes(this.paymentStatus)) {
      this._showPaymentPending()
      this.enableUI()
    } else if (this.isBookingNumberFromServer) {
      this.enableUI()
    }
  }

  _returnAcceptedBrands (brands) {
    if (!brands) { return '' }
    return brands.map(brand => { return `<img class="w-payment__brand-img" src="${brand.imageUrl}" alt="${brand.paymentMethodId}" />` }).join('')
  }

  _clickedPaymentMethod (options, oldOptions) {
    const selectedOption = options.find(option => option.checked)
    if (selectedOption) {
      flush(this.alertContainer)
      this._renderCompactOptionsList(selectedOption)

      if (document.defaultView.getComputedStyle(this.paymentContainer).flexDirection === 'column' && this.optionsElement) {
        this.optionsElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  _initAttributesFromUrl () {
    const urlParams = new URLSearchParams(window.location.search)

    this.bookingNumber = urlParams.get(attr.bookingNumberFromServer) || urlParams.get(attr.bookingNumFromServer)
    this.groupUserId = urlParams.get(attr.groupUserIdFromServer) || null
    this.fullPaymentAmount = urlParams.get(attr.fullPaymentAmount) || null
    this.downPaymentAmount = urlParams.get(attr.downPaymentAmount) || null
    this.partialPaymentAmount = null
    this.paymentStatus = urlParams.get(attr.paymentStatusFromUrl) || null
  }

  _clickedPaymentOption (options, oldOptions) {
    const selectedPaymentMethod = this.choiceListMethodsApi.getSelectedValues()
    const selectedOption = options.find(option => option.checked)

    if (selectedOption?.value?.toLowerCase() === 'custompartial') {
      this._partialPayment(selectedOption, selectedPaymentMethod)
    } else {
      this._renderAdyenPaymentIntegration()
      if (!this._checkIfAdyenIsThePaymentProvider()) { this.events.emit(paymentEvents.PROVIDER_MOUNTED) }
    }
  }

  _partialPayment (selectedOption, selectedPaymentMethod) {
    this.partialPayment = new Partial(this.choiceListPaymentOptionsElement, {
      selectedOption,
      locales: this.locales,
      events: this.events,
      maxAmount: this.apiData.partialMaxAmount,
      priceConfiguration: this.apiData.priceConfiguration,
      priceInfo: this.paymentOptions[this.componentId].find(pm => pm.paymentMethodType.toLowerCase() === selectedPaymentMethod[0].toLowerCase()),
      adyenImplementation: this.adyenImplementation
    })

    this.events.on(payment.PARTIAL_PAY, (amount) => {
      this.partialPaymentAmount = amount
      this._renderAdyenPaymentIntegration()
    }, this)

    this.events.on(payment.PARTIAL_REMOVE, () => {
      if (this.adyenImplementation) {
        this.adyenImplementation.unmountMethod()
        this.adyenImplementation = null
      }
    }, this)
  }

  _enableSubmitButton () {
    if (this.submitButtonApi) {
      this.submitButtonApi.setProps({
        disabled: false,
        loading: false
      })
    }
  }

  async _clickedPaybutton (el) {
    const selectedPaymentOption = this.choiceListOptionsApi.getProp('options')
    this.choiceListMethodsApi.disableComponent()
    this.choiceListOptionsApi.disableComponent()
    this.choiceListOptionsApi.setProp('options', selectedPaymentOption)
    if (this.choiceListMethodsApi.getSelectedValues().length > 0 && this.choiceListOptionsApi.getSelectedValues().length > 0) {
      const paymentMethod = this.choiceListMethodsApi.getSelectedValues()[0]
      const paymentType = this.choiceListOptionsApi.getSelectedValues()[0]
      this._proceedThePayment(paymentMethod, paymentType)
    } else {
      this._showPaymentError()
    }
  }

  async _proceedThePayment (paymentMethod, paymentType) {
    if (!this._checkIfAdyenIsThePaymentProvider()) {
      this._emitPaymentMethodSelected(paymentMethod, paymentType)
      await this._postToRetrieveParams()
    }
  }

  _clickedConfirmBookingButton (confirmButtonUrl) {
    const eventData = {
      url: confirmButtonUrl
    }
    this.events.emit(bookingStepsEvents.NEXT_STEP_PROCESS_STARTED, eventData)
  }

  _emitPaymentMethodSelected (paymentMethod, paymentType) {
    const mappedPaymentType = this._getPaymentTypeMapped(paymentType)
    const eventData = {
      paymentMethod: paymentMethod.toLowerCase(),
      paymentType: mappedPaymentType,
      amountPaid: this._getAmountSelected(paymentMethod, paymentType),
      manual: 'no'
    }
    this.events.emit(paymentEvents.PAYMENT_METHOD_SELECTED, eventData)
  }

  _getPaymentTypeMapped (paymentType) {
    if (!paymentType) { return paymentTypes.FULL }
    if (paymentType.toLowerCase() === 'custompartial') { return paymentTypes.PARTIAL }
    if (paymentType.toLowerCase() === 'partial') { return paymentTypes.DEPOSIT }
    if (paymentType.toLowerCase() === 'full') { return paymentTypes.FULL }
  }

  _getAmountSelected (selectedPaymentMethod, selectedPaymentOption) {
    const paymentMethod = this.paymentOptions[this.componentId].find(currentPaymentMethod => currentPaymentMethod.paymentMethodId.toLowerCase() === selectedPaymentMethod.toLowerCase())
    if (paymentMethod) {
      const amount = paymentMethod.amounts.find(x => x.type.toLowerCase() === selectedPaymentOption.toLowerCase())
      return !amount ? 0 : amount.price
    }
    return 0
  }

  _getBookingIdentifier () {
    let bookingIdentifier = null
    if (this.data && this.data.draftId) {
      bookingIdentifier = this.data.draftId
    }
    if (this.data && this.data.payment && this.data.payment.bookingNumber) {
      bookingIdentifier = this.data.payment.bookingNumber
    }
    return bookingIdentifier
  }

  getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.componentId}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return customLocaleData || {}
  }

  async _postToRetrieveParams () {
    try {
      const result = await this._postToCreateParams()

      if (result.success) {
        this.configurations.successUrl = result.response.successUrl || null
        this.apiParamsData = result.response
        if (this.apiParamsData) {
          if (this.apiParamsData.ogone) {
            this._renderPaymentParamsForm(this.apiParamsData.ogone.formFields)
          } else if (this.apiParamsData.buckaroo) {
            window.location.href = this.apiParamsData.buckaroo.redirectUrl
          } else {
            this._renderPaymentParamsForm(this.apiParamsData)
          }
        }
      } else {
        this._showPaymentError()
      }
    } catch (error) {
      this._showPaymentError()
    }
  }

  async _postToCreateParams () {
    const data = {
      bookingNumber: this.bookingNumber,
      paymentmethod: this.choiceListMethodsApi.getSelectedValues()[0],
      paymentoption: this.choiceListOptionsApi.getSelectedValues()[0],
      fullPaymentAmount: this.fullPaymentAmount || null,
      downPaymentAmount: this.downPaymentAmount || null,
      partialPaymentAmount: this.partialPaymentAmount || null,
      groupUserId: this.groupUserId || null,
      contextitemid: this.componentId,
      referrerUrl: window.location.href
    }

    return await apiCaller(`${this.configurations.createParametersUrl}`, { body: data, method: 'POST' })
  }

  _isTransactionTypeOnline (transactionType) {
    return transactionType === transactionTypes.ONLINE
  }

  _isTransactionTypeOffline (transactionType) {
    return transactionType === transactionTypes.OFFLINE
  }

  _isTransactionTypeVoucher (transactionType) {
    return transactionType === transactionTypes.VOUCHER
  }

  _checkIfAdyenIsThePaymentProvider () {
    return this.paymentProvider === paymentProviders.ADYEN
  }

  _checkIfHasMandatoryPayment () {
    return this.data && this.data.hasMandatoryPayment
  }

  _checkIfIsRebooking () {
    return this.data && this.data.isRebooking
  }

  _translateOptionsBasedOnSelectedMethodModel () {
    const selectedPaymentMethod = this.choiceListMethodsApi.getSelectedValues()
    const optionsInfo = this.paymentOptions[this.componentId].find(pm => pm.paymentMethodType.toLowerCase() === selectedPaymentMethod[0].toLowerCase())
    const widgetOptionsData = {
      id: `paymentOptions${this.componentId}`,
      name: `paymentOptions${this.componentId}`,
      items: []
    }
    if (optionsInfo && optionsInfo.amounts.length > 0) {
      optionsInfo.amounts.forEach(amount => {
        const optionInfo = {
          id: amount.type,
          value: amount.type,
          text: this._getTypeText(amount.type),
          additionalText: amount.priceText
        }

        if (amount.type.toLowerCase() === 'custompartial') {
          optionInfo.additionalText = ''
          optionInfo.showExtraContentDiv = true
          optionInfo.extraClasses = 'w-payment__payment-option-custompartial'
        }

        widgetOptionsData.items.push(optionInfo)
      })
    }
    return widgetOptionsData
  }

  _getTypeText (type) {
    let text = this.locales.paymentDepositText
    switch (type.toLowerCase()) {
      case 'full':
        text = this.locales.paymentFullText
        break
      case 'custompartial':
        text = this.locales.paymentPartialText
        break
    }
    return text
  }

  _attachEvents () {
    this.choiceListPaymentMethodsElement = this.element.querySelector(widgetQueries.choiceListsPaymentMethods)
    this.choiceListMethodsApi = this.choiceListPaymentMethodsElement && this.choiceListPaymentMethodsElement[widgetQueries.choiceListApi]
    this.choiceListMethodsApi.events.on('changeOptions', this._clickedPaymentMethod.bind(this))
  }

  _attachOptionsEvents () {
    this.choiceListPaymentOptionsElement = this.element.querySelector(widgetQueries.choiceListsPaymentOptions)
    this.choiceListOptionsApi = this.choiceListPaymentOptionsElement[widgetQueries.choiceListApi]
    this.choiceListOptionsApi.events.on('changeOptions', this._clickedPaymentOption.bind(this))

    const submitButton = this.element.querySelector(widgetQueries.buttonSubmit)
    this.submitButtonApi = submitButton && submitButton[widgetQueries.buttonApi]
    this.submitButtonApi && this.submitButtonApi.events.on('clickButton', this._clickedPaybutton.bind(this))
  }

  _attachVoucherEvents () {
    const eventsToRemove = [paymentEvents.VOUCHER_SELECTED, paymentEvents.VOUCHER_FETCHED, paymentEvents.ADD_VOUCHER, paymentEvents.CANCELED, paymentEvents.PAYMENT_SUCCESS, paymentEvents.PAYMENT_ERROR]
    eventsToRemove.forEach(ev => this.events.removeAllListeners(ev))

    this.events.on(paymentEvents.VOUCHER_SELECTED, (data) => this._showNextScreen(this._showVoucherOptions, data), this)
    this.events.on(paymentEvents.ADD_VOUCHER, () => this._showNextScreen(this._showVoucherRedeemer), this)
    this.events.on(paymentEvents.VOUCHER_FETCHED, (data) => this._showNextScreen(this._showVoucherOptions, data), this)
    this.events.on(paymentEvents.CANCELED, () => this._showNextScreen(this._showVoucherScreen), this)
    this.events.on(paymentEvents.PAYMENT_SUCCESS, (data) => this._showNextScreen(this._showVoucherPaymentSuccess, data), this)
    this.events.on(paymentEvents.PAYMENT_ERROR, () => this._showNextScreen(this._showVoucherPaymentError), this)
  }

  _attachPaymentEvents () {
    const eventsToRemove = [paymentEvents.PAYMENT_SUCCESS, paymentEvents.PAYMENT_ERROR, paymentEvents.PAYMENT_PENDING, paymentEvents.PAYMENT_FAILED]
    eventsToRemove.forEach(ev => this.events.removeAllListeners(ev))

    this.events.on(paymentEvents.PAYMENT_SUCCESS, () => this._showPaymentSuccessPage(), this)
    this.events.on(paymentEvents.PAYMENT_PENDING, () => this._showPaymentPendingPage(), this)

    this.events.on(paymentEvents.PAYMENT_ERROR, () => this._showPaymentError(true), this)
    this.events.on(paymentEvents.PAYMENT_FAILED, () => this._showPaymentError(true), this)

    this.events.on(paymentEvents.PROVIDER_MOUNTED, () => { this._enableSubmitButton() }, this)
    this.events.on(paymentEvents.ON_SUBMIT, (data) => this._emitPaymentMethodSelected(data.paymentMethod, data.paymentType), this)

    this.events.on(paymentEvents.CONFIG_LOADED, () => this._hideLoader(), this)
  }

  _attachConfirmButtonEvents (confirmButtonUrl) {
    const confirmButton = this.confirmButtonContainer.querySelector(widgetQueries.buttonConfirmBooking)
    this.confirmButtonApi = confirmButton && confirmButton[widgetQueries.buttonApi]
    this.confirmButtonApi && this.confirmButtonApi.events.on('clickButton', () => this._clickedConfirmBookingButton(confirmButtonUrl, this))
  }

  // no payment methods
  _init () {
    this.paymentContainer = this.element.querySelector(widgetQueries.paymentContainer)
    this.paymentMethodsListContainer = this.element.querySelector(widgetQueries.paymentMethodsList)
    this.choiceListMethodsApi = null
    this.choiceListOptionsApi = null
    this.submitButtonApi = null
    this.paymentProvider = null
    this.paymentForm = this.element.querySelector(widgetQueries.paymentForm)
    this.alertContainer = this.element.querySelector(widgetQueries.paymentAlert)
    this.confirmButtonContainer = this.element.querySelector(widgetQueries.confirmButtonContainer)
    this.partialPayment = null
  }

  _initConfigurations () {
    const configurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(configurationElement) || {}
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)
      const options = {
        homeUrl: configuration[configOptions.homeUrl] || null,
        successUrl: null,
        successIconUrl: configuration[configOptions.successIconUrl] || null,
        optionsUrl: configuration[configOptions.optionsUrl] || this.element.dataset[attr.optionsUrl],
        createParametersUrl: configuration[configOptions.createParametersUrl] || this.element.dataset[attr.createParametersUrl],
        initiatePaymentUrl: configuration[configOptions.initiatePaymentUrl] || this.element.dataset[attr.initiatePaymentUrl],
        submitAdditionalDetailsUrl: configuration[configOptions.submitAdditionalDetailsUrl] || this.element.dataset[attr.submitAdditionalDetailsUrl],
        checkVoucherUrl: configuration[configOptions.checkVoucherUrl] || this.element.dataset[attr.checkVoucherUrl],
        checkVoucherMethod: configuration[configOptions.checkVoucherMethod] || this.element.dataset[attr.checkVoucherMethod],
        applyVoucherUrl: configuration[configOptions.applyVoucherUrl] || this.element.dataset[attr.applyVoucherUrl]
      }
      return options
    }
  }

  _initOnlineOptions () {
    this.optionsElementOfflineMessage = this.element.querySelector(widgetQueries.offlineMessages)
    this.optionsElement = this.element.querySelector(widgetQueries.paymentOptionsContainer)
    this.optionsElementList = this.element.querySelector(widgetQueries.paymentOptionsList)
    this.adyenPaymentProviderContainer = this.element.querySelector(widgetQueries.adyenPaymentProvider)
    this.loaderElement = this.element.querySelector(widgetQueries.paymentMethodLoader)
  }

  _showVoucherPaymentError () {
    this._showPaymentContainer(false)
    this._showAlert(this.locales.voucherErrorTitle, this.locales.voucherErrorDescription, 'warning')
  }

  _showPaymentError (isShown = false) {
    this._showPaymentContainer(isShown)
    this._showAlert(this.locales.paymentErrorTitle, this.locales.paymentErrorDescription, 'warning')
  }

  _showPaymentPartlyPaid () {
    flush(this.paymentContainer)
    this._showAlert(this.locales.partlyPaidTitle, this.locales.partlyPaidDescription || '', 'info')
    this.enableUI()
  }

  _showPaymentFullyPaid () {
    flush(this.paymentContainer)
    this._showAlert(this.locales.fullyPaidTitle, this.locales.fullyPaidDescription || '', 'info')
    this.enableUI()
  }

  _showPaymentSuccess () {
    flush(this.paymentContainer)
    this._showAlert(this.locales.paymentSuccessTitle, this.locales.paymentSuccessDescription, 'success')
  }

  _showPaymentPending () {
    flush(this.paymentContainer)
    this._showAlert(this.locales.paymentPendingTitle, this.locales.paymentPendingDescription, 'info')
  }

  _showDownPaymentAlreadyCovered () {
    flush(this.paymentContainer)
    if (this.locales.downPaymentAlreadyCoveredTitle) {
      this._showAlert(this.locales.downPaymentAlreadyCoveredTitle, this.locales.downPaymentAlreadyCoveredDescription || '', 'info')
    }
    this._showConfirmButton()
    this.enableUI()
    this.events.emit(paymentEvents.DOWN_PAYMENT_COVERED)
  }

  _showVoucherPaymentSuccess (data) {
    flush(this.paymentContainer)

    let message = this.locales.voucherSuccessDescription || ''

    if (data && !data.isVoucherFullyRedeemed && data.isBookingFullyPaid) {
      message = this.locales.voucherPartlyRedeemedAndBookingFullyPaidText || message
    } else if (data && data.isVoucherFullyRedeemed && data.isBookingFullyPaid) {
      message = this.locales.voucherFullyRedeemedAndBookingFullyPaidText || message
    } else if (data && !data.isVoucherFullyRedeemed && data.isBookingDepositPaid) {
      message = this.locales.voucherPartlyRedeemedAndDepositPaidText || message
    } else if (data && data.isVoucherFullyRedeemed && data.isBookingDepositPaid) {
      message = this.locales.voucherFullyRedeemedAndDepositPaidText || message
    } else if (data && data.isVoucherFullyRedeemed && !data.isBookingDepositPaid) {
      message = this.locales.voucherFullyRedeemedAndDepositNotPaidText || message
    } else if (data && !data.isVoucherFullyRedeemed && !data.isBookingDepositPaid) {
      message = this.locales.voucherPartlyRedeemedAndDepositNotPaidText || message
    }

    if (data && message) {
      message = message.replace('{VOUCHER_REMAINING_AMOUNT}', data.voucherRemainingAmount)
        .replace('{VOUCHER_TRANSACTION_AMOUNT}', data.voucherTransactionAmount)
        .replace('{VOUCHER_TOTAL_AMOUNT}', data.voucherTotalAmount)
        .replace('{BOOKING_DEPOSIT_AMOUNT}', data.depositBookingAmount)
        .replace('{BOOKING_FULL_AMOUNT}', data.bookingAmount)
        .replace('{BOOKING_REST_AMOUNT}', data.bookingRestAmount)
        .replace('{BOOKING_DEPOSIT_REST_AMOUNT}', data.depositRestAmount)
    }

    this._showAlert(this.locales.voucherSuccessTitle, message, 'success')
  }

  _showAlert (title, message, state) {
    const node = {
      content: {
        title,
        description: message || ''
      },
      successIconUrl: (state === 'success' && this.configurations.successIconUrl) ? this.configurations.successIconUrl : '',
      backgroundConfetti: this._generateConfetti(state),
      extraClasses: `cs-state-${state} cs-state-${state}--light`
    }

    const renderedHtml = PaymentAlertTemplate(node)

    const newContent = elementFromString(renderedHtml)
    Component.initDocumentComponentsFromAPI(newContent)
    this._renderAlert(newContent)
  }

  _showConfirmButton () {
    if (!this.data && !this.data.navigation) { return }
    if (!this.locales.downPaymentCoveredConfirmButtonText) { return }
    const confirmButtonUrl = this.data.navigation.nextButtonUrl || null
    const html = BtnTemplate({
      ...defaultButtonData,
      ...{
        text: this.locales.downPaymentCoveredConfirmButtonText,
        variant: 'flow',
        icon: 'chevron-right',
        iconPosition: 'right',
        url: confirmButtonUrl,
        track: this.track,
        attributes: { 'data-w-payment__confirm-booking-button': '' }
      }
    })
    const content = elementFromString(html)
    Component.initDocumentComponentsFromAPI(content)
    this._renderConfirmButton(content)
    this._attachConfirmButtonEvents(confirmButtonUrl)
  }

  _generateConfetti (state) {
    if (state !== 'success') { return }
    let confetti = ''
    for (let i = 0; i < confettiLength; i++) {
      confetti += `<div class="confetti-${i}"></div>`
    }
    return confetti
  }

  _showPaymentContainer (isShown) {
    if (this.apiData && this.apiData.paymentMethods && isShown) {
      this._renderPaymentMethodsList()
    }
  }

  _showVoucherScreen () {
    if (this.apiData.vouchers) {
      this._showVoucherSelector()
    } else {
      this._showVoucherRedeemer()
    }
  }

  _showPaymentSuccessPage () {
    if (this.configurations && this.configurations.successUrl) {
      window.location.href = this.configurations.successUrl
    }
    if (this.configurations && !this.configurations.successUrl) {
      this._showPaymentSuccess()
    }
  }

  _showPaymentPendingPage () {
    if (this.configurations && this.configurations.successUrl) {
      window.location.href = this.configurations.successUrl
    }
    if (this.configurations && !this.configurations.successUrl) {
      this._showPaymentPending()
    }
  }

  _showNextScreen (functionToCall, data) {
    this.voucherScreen = null
    functionToCall.call(this, data)
  }

  _showVoucherSelector () {
    this.voucherScreen = new VoucherSelector(this.voucherOption, {
      locales: this.locales,
      events: this.events,
      vouchers: this.apiData.vouchers
    })
  }

  _showVoucherRedeemer () {
    this.voucherScreen = new VoucherRedeemer(this.voucherOption, {
      locales: this.locales,
      url: this.configurations.checkVoucherUrl,
      method: this.configurations.checkVoucherMethod,
      componentId: this.componentId,
      events: this.events,
      showCancelButton: !!this.apiData.vouchers,
      track: this.track
    })
  }

  _showVoucherOptions (data) {
    this.voucherScreen = new VoucherOptions(this.voucherOption, {
      locales: this.locales,
      url: this.configurations.applyVoucherUrl,
      componentId: this.componentId,
      bookingNumber: this.bookingNumber || '',
      groupUserId: this.groupUserId || '',
      paymentMethod: this.choiceListMethodsApi.getSelectedValues().length > 0 ? this.choiceListMethodsApi.getSelectedValues()[0] : '',
      voucherPriceInfo: this.paymentOptions[this.componentId].find(pm => pm.paymentMethodType.toLowerCase() === 'voucher'),
      voucherData: data,
      events: this.events,
      mainBookerEmail: '',
      isLoggedIn: !!this.apiData.vouchers,
      hasOwnVouchers: this.apiData.vouchers && this.apiData.vouchers.length > 1,
      hasSomeOwnBonusVouchers: this.apiData.vouchers && this.apiData.vouchers.length > 1 && this.apiData.vouchers.some(v => v.bonus)
    })
  }

  _renderPaymentMethodsList () {
    if (this.paymentMethodsListContainer) {
      const paymentMethodsToBeRendered = this.apiData.paymentMethods.map(p => {
        return {
          text: p.text ? p.text : p.paymentMethodType,
          value: p.groupCode || p.paymentMethodId,
          id: p.groupCode ? `payment-method_${p.groupCode}` : `payment-method_${p.paymentMethodId}`,
          additionalText: (p.additionalText ? p.additionalText : '') + this._returnAcceptedBrands(p.brands),
          extraClasses: 'w-payment__payment-method',
          showExtraContentDiv: true,
          dataset: { imageSrc: p.imageUrl, imageAlt: p.text, text: p.text, transactionType: p.transactionType }
        }
      })

      const renderedHtml = `
        ${ChoiceListTemplate({
          ...defaultChoiceListData,
          ...{
            name: `paymentMethods-${this.componentId}`,
            id: `paymentMethods-${this.componentId}`,
            variant: 'boxed',
            method: 'single',
            items: paymentMethodsToBeRendered,
            extraClasses: 'w-payment__payment-methods-choice-list',
            attributes: { 'data-w-payment__payment-methods-choice-list': '' }
          }
        })}
      `

      const newContent = elementFromString(renderedHtml)
      Component.initDocumentComponentsFromAPI(newContent)
      this._renderPaymentMethods(newContent)
      // Attach events to the choice list elements in the widget
      this._attachEvents()
      if (this.isBookingNumberFromServer) {
        this.enableUI()
      }
    }
  }

  async _renderAdyenPaymentIntegration () {
    if (this._checkIfAdyenIsThePaymentProvider()) {
      this._showLoader()
      try {
        if (this.adyenImplementation) {
          this.adyenImplementation.unmountMethod()
          this.adyenImplementation = null
        }
        const resultCreateParams = await this._postToCreateParams()

        if (resultCreateParams.success) {
          this.configurations.successUrl = resultCreateParams.response.successUrl || null

          if (!this.adyenImplementation) {
            this.adyenImplementation = new AdyenImplementation(this.adyenPaymentProviderContainer, {
              createParams: resultCreateParams.response && resultCreateParams.response.adyen,
              initiatePaymentUrl: this.configurations.initiatePaymentUrl,
              submitAdditionalDetailsUrl: this.configurations.submitAdditionalDetailsUrl,
              paymentMethodBrand: this.choiceListMethodsApi.getSelectedValues()[0].toLowerCase(),
              paymentOption: this.choiceListOptionsApi.getSelectedValues()[0].toLowerCase(),
              extraParams: {},
              bookingNumber: this.bookingNumber,
              parent: this,
              events: this.events
            })
          }
          this.adyenImplementation.initCheckout()
        } else {
          this._hideLoader()
          this._showPaymentError()
        }
      } catch (error) {
        this._hideLoader()
        this._showPaymentError()
      }
    }
  }

  _hideLoader () {
    this.loaderElement && this.loaderElement.classList.add(classNames.hidden)
  }

  _showLoader () {
    this.loaderElement && this.loaderElement.classList.remove(classNames.hidden)
  }

  _renderPaymentParamsForm (data) {
    if (this.apiParamsData) {
      const renderedHtml = PaymentFormTemplate(data)

      const newContent = elementFromString(renderedHtml)
      Component.initDocumentComponentsFromAPI(newContent)
      this._renderForm(newContent)
      this.paymentFormHidden = this.element.querySelector(widgetQueries.paymentFormHidden)
      this.paymentFormHidden.submit()
    }
  }

  _renderCompactOptionsList (selectedOption) {
    const selectedOptionId = selectedOption.id
    const choiceListItemSelected = this.choiceListPaymentMethodsElement.querySelector(`[id$='${selectedOptionId}']`).parentElement

    const translatedWidgetData = this._translateOptionsBasedOnSelectedMethodModel()
    const dataContainer = {
      isPartialVoucherPaymentEnabled: !!this.element.getAttribute(attr.isPartialVoucherPaymentEnabled),
      options: translatedWidgetData,
      isTransactionTypeOnline: this._isTransactionTypeOnline(selectedOption.dataset.transactionType),
      isTransactionTypeOffline: this._isTransactionTypeOffline(selectedOption.dataset.transactionType),
      isTransactionTypeVoucher: this._isTransactionTypeVoucher(selectedOption.dataset.transactionType),
      isAdyenPaymentProvider: this._checkIfAdyenIsThePaymentProvider(),
      showPayButton: !this._checkIfAdyenIsThePaymentProvider(),
      paymentMethodSelected: this._checkIfAdyenIsThePaymentProvider() ? selectedOption.value : null,
      ...this.locales
    }

    const renderPaymentOptionsContainerHtml = PaymentOptionsContainerTemplate(dataContainer)

    const newContent = elementFromString(renderPaymentOptionsContainerHtml)
    Component.initDocumentComponentsFromAPI(newContent)
    const radioButtonExtraContentHtml = choiceListItemSelected.querySelector(widgetQueries.radioButtonExtraContent)
    this._renderPaymentMethodOptions(newContent, radioButtonExtraContentHtml)

    if (this._isTransactionTypeOnline(selectedOption.dataset.transactionType)) {
      this._attachOptionsEvents()
      this._attachPaymentEvents()
      this._initOnlineOptions()
    }
    if (this._isTransactionTypeVoucher(selectedOption.dataset.transactionType)) {
      this.voucherOption = this.element.querySelector(widgetQueries.paymentVoucherOption)
      this._attachVoucherEvents()
      this._showVoucherScreen()
    }
  }

  _renderPaymentMethods (el) {
    flush(this.paymentMethodsListContainer)
    this.paymentMethodsListContainer.appendChild(el)
  }

  _renderPaymentMethodOptions (el, htmlContent) {
    flush(htmlContent)
    htmlContent.appendChild(el)
  }

  _render (el) {
    flush(this.optionsElementList)
    this.optionsElementList.appendChild(el)
  }

  _renderForm (el) {
    flush(this.paymentForm)
    this.paymentForm.appendChild(el)
  }

  _renderAlert (el) {
    flush(this.alertContainer)
    this.alertContainer.appendChild(el)
  }

  _renderConfirmButton (el) {
    flush(this.confirmButtonContainer)
    this.confirmButtonContainer.appendChild(el)
  }
}

registerWidget(Payment, widgetApi)
