import domEventsHelper from '../../../js/document/dom-events-helper'
import {
  PromotedPriceFallbackTemplate,
  PromotedPriceStickyFallbackTemplate
} from './w-promoted-price-fallback.template'
import { PromotedPriceTemplate } from './w-promoted-price.template'
import { PromotedPriceStickyTemplate } from './w-promoted-price-sticky.template'
import { fetchJsonDataAndStatusInfo } from '../../../js/helpers/json-fetch'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { getPriceData, getPackageBlockData, getSkiPassPublicPriceText } from './data-processor'
import { arrayifyObject } from '../../../js/helpers/arrayify-object'
import { getUrlFromString, getSearchParamsFromUrl } from '../../../js/document/url'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
import { elementFromString } from '../../../js/document/html-helper'
import { forceRepaint } from '../../../js/document/css'
import registeredEvents from '../../../js/helpers/registered-events'
import Component from '../../../js/core/component/component'
import { promotedPriceEvents } from '../../../js/document/event-types'
import { GET_PASSTHROUGH_PARAMS } from '../../../js/helpers/pass-through-params'

const COMPONENT_LOCALES = register(`window.sundio.i18n.${language}.promotedPrice`)

const EventEmitter = require('eventemitter3')
const widgetApi = 'w-promoted-price'

const widgetQueries = {
  urlAttr: `data-${widgetApi}__api-url`,
  mainContainer: `.${widgetApi}__main`,
  stickyContainer: `.${widgetApi}__sticky`,
  stickyEnabled: `data-${widgetApi}__sticky-enabled`,
  extraParams: 'input[type="hidden"]',
  button: `.${widgetApi}__btn`,
  modalId: `data-${widgetApi}__modal-id`,
  dataSource: `[data-${widgetApi}__data-source]`,
  hasUiTest: `data-${widgetApi}__has-ui-test`,
  trackAttr: 'data-track'
}

/**
 * PromotedPrice content
 *
 */
export default class PromotedPrice {
  /**
   * Creates a new PromotedPrice behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   * @param {Object} [options={}] - Options object
   * @param {String} [options.url] - API url
   * @param {String} [options.modalId] - Modal ID (used by ACM)
   * @param {Object} [options.dataSource] - Optional static JSON data to render on experience editor
   */
  constructor (element, options = {}) {
    this.element = element
    this.elements = this._runElementQueries()
    this.extraParams = this._getExtraParamsFromDom()
    this.apiUrl = options.url || this.element.getAttribute(widgetQueries.urlAttr)
    this.stickyEnabled = options.stickyEnabled || this.element.hasAttribute(widgetQueries.stickyEnabled) || false
    this.modalId = options.modalId || this.element.getAttribute(widgetQueries.modalId)
    this.dataSource = options.dataSource || this._getDataSourceFromDom()
    this.hasUiTest = options.hasUiTest || this.element.hasAttribute(widgetQueries.hasUiTest) || false
    this.isOpened = this.element.classList.contains('in')
    this.isPricesTab = false

    this.passThroughParams = GET_PASSTHROUGH_PARAMS(getSearchParamsFromUrl(document.location))

    this.events = new EventEmitter()

    this.locales = this._getLocales()

    this.element[widgetApi] = {
      refresh: this.refresh.bind(this),
      showFallback: this.showFallback.bind(this),
      open: this.open.bind(this),
      close: this.close.bind(this),
      showStickyPromotedPrice: this._showStickyPromotedPrice.bind(this),
      events: this.events,
      getOriginalDepartureDate: this._getOriginalDepartureDate.bind(this)
    }

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...(this.element.hasAttribute(widgetQueries.trackAttr) && {
        track: this.element.attributes[widgetQueries.trackAttr].value
      })
    })

    this._buildEvents()
    domEventsHelper.attachEvents(this._domEvents, widgetApi)

    if (this.dataSource) {
      this.refresh(
        {},
        {
          dataSource: this.dataSource,
          hasUiTest: this.hasUiTest
        }
      )
    }

    this.events.on(promotedPriceEvents.PROMOTED_PRICE_DATA_LOADED, d => {
      this.addBannerifDiscount(d, this.locales)
    })
  }

  /*
   * -----------------------------------------------------
   * INITIALIZATION RELATED METHODS
   * -----------------------------------------------------
   * */

  /**
   * Runs element queries
   *
   * @returns {Object}
   */
  _runElementQueries () {
    return {
      mainContainer: this.element.querySelector(widgetQueries.mainContainer)
    }
  }

  /**
   * Fetch promoted price data from API
   *
   * @param {Object} parameters - Parameters to be sent on calling API
   * @param {Object} [options={}]
   * @param {Object} [options.dataSource] - If provided will skip to call API
   *
   * @returns {Promise} raw data from API
   */
  async fetch (parameters, options = {}) {
    if (options.dataSource) {
      return {
        rawData: options.dataSource,
        data: this._getPromotedPriceData(options.dataSource)
      }
    }

    if (!this.apiUrl) return undefined

    const promotedPriceUrl = getUrlFromString(
      this.apiUrl,
      arrayifyObject({ ...this.extraParams, ...parameters, ...this.passThroughParams })
    )
    const promotedPriceResponseAndStatus = await fetchJsonDataAndStatusInfo(promotedPriceUrl, {
      fullReferrerOnCrossOrigin: true
    })
    if (!promotedPriceResponseAndStatus.ok || promotedPriceResponseAndStatus.statusCode === 204) {
      return null
    }
    const promotedPriceResponse = promotedPriceResponseAndStatus.jsonData
    return {
      rawData: promotedPriceResponse,
      data: this._getPromotedPriceData(promotedPriceResponse)
    }
  }

  /**
   * Recreates HTML content
   *
   * @param {object} parameters to fetch promoted price API
   * @param {Object} [options={}]
   * @param {Object} [options.dataSource] - If provided will skip to call API
   */
  async refresh (parameters, options = {}) {
    domEventsHelper.detachEvents(this._domEvents, widgetApi)

    const actionsQueue = await Promise.all([this.fetch(parameters, options), this.close()])

    this.promotedPriceData = actionsQueue[0]
    if (!this.promotedPriceData) {
      this.showFallback()
      return
    }

    const promotedPriceData = { ...this.promotedPriceData.data, hasUiTest: this.hasUiTest }
    promotedPriceData.rawDepartureDate = this.promotedPriceData.rawData.departureDate.raw // Required for the UI tests.
    promotedPriceData.mandatoryExtraCostsText = this.promotedPriceData.rawData.price.mandatoryExtraCostsText
    promotedPriceData.staticText = this.promotedPriceData.rawData.price.staticText
    promotedPriceData.scratchPriceDescription = this.promotedPriceData.rawData.price.scratchPriceDescription
    promotedPriceData.outboundDepartureAirportName = this.promotedPriceData.rawData.outboundDepartureAirportName
    this.originalDepartureDate = this.originalDepartureDate
      ? this.originalDepartureDate
      : promotedPriceData.rawDepartureDate

    const newMainContent = elementFromString(PromotedPriceTemplate(promotedPriceData))
    if (this.elements.mainContainer) this.elements.mainContainer.replaceWith(newMainContent)
    this.elements.mainContainer = newMainContent
    Component.initComponentActionElements(newMainContent)

    if (this.stickyEnabled) {
      this.elements.stickyContainer = document.body.querySelector(widgetQueries.stickyContainer)
      const mustAppendElement = !this.elements.stickyContainer

      const newStickyContent = elementFromString(PromotedPriceStickyTemplate(promotedPriceData))
      if (this.elements.stickyContainer) this.elements.stickyContainer.replaceWith(newStickyContent)
      this.elements.stickyContainer = newStickyContent
      this._showStickyPromotedPrice({ isPricesTab: this.isPricesTab })
      Component.initComponentActionElements(newStickyContent)

      if (mustAppendElement) {
        document.body.appendChild(newStickyContent)

        const buttonSticky = this.elements.stickyContainer.querySelector(widgetQueries.button)
        buttonSticky.addEventListener('click', ev => this._handlePromotedPriceButtonClick(ev))
      }
    }

    this._buildEvents()
    domEventsHelper.attachEvents(this._domEvents, widgetApi)

    this.events.emit(promotedPriceEvents.PROMOTED_PRICE_DATA_LOADED, {
      ...this.promotedPriceData.rawData,
      participants: parameters.Participants[0]
    })

    return this.open()
  }

  showFallback () {
    const accosWithTabs = this.element.closest('.l-site-main__content--accommodation-no-tabs') === null
    if (accosWithTabs) {
      // Fallback shouldn't be shown when acco is configured WITH tabs because user can click on Prices tab by themselves.
      return
    }

    domEventsHelper.detachEvents(this._domEvents, widgetApi)

    const promotedPriceData = {
      rawData: undefined,
      title: this.locales.fallbackTitle,
      description: this.locales.fallbackDescription,
      buttonText: this.locales.fallbackButtonText ?? this.locales.textButton,
      hasUiTest: this.hasUiTest
    }

    const newMainContent = elementFromString(PromotedPriceFallbackTemplate(promotedPriceData))
    if (this.elements.mainContainer) this.elements.mainContainer.replaceWith(newMainContent)
    this.elements.mainContainer = newMainContent
    Component.initComponentActionElements(newMainContent)

    if (this.stickyEnabled) {
      this.elements.stickyContainer = document.body.querySelector(widgetQueries.stickyContainer)
      const mustAppendElement = !this.elements.stickyContainer

      const newStickyContent = elementFromString(PromotedPriceStickyFallbackTemplate(promotedPriceData))
      if (this.elements.stickyContainer) this.elements.stickyContainer.replaceWith(newStickyContent)
      this.elements.stickyContainer = newStickyContent
      this._showStickyPromotedPrice({ isPricesTab: this.isPricesTab })
      Component.initComponentActionElements(newStickyContent)

      if (mustAppendElement) {
        document.body.appendChild(newStickyContent)

        const buttonSticky = this.elements.stickyContainer.querySelector(widgetQueries.button)
        buttonSticky.addEventListener('click', ev => this._handlePromotedPriceButtonClick(ev))
      }
    }

    this._buildEvents()
    domEventsHelper.attachEvents(this._domEvents, widgetApi)

    // emit event for FALLBACK LOADED ?? Do we want to track fallbacks in Google Analytics??
    // this.events.emit(promotedPriceEvents.PROMOTED_PRICE_DATA_LOADED, { ...this.promotedPriceData.rawData, participants: parameters.Participants[0] })

    return this.open()
  }

  /**
   * Opening animation to show promoted price
   */
  open () {
    return new Promise(resolve => {
      if (this.isOpened) return resolve()
      this.isOpened = true
      this.element.classList.add('is-opening')
      forceRepaint(this.element)
      this.element.classList.add('in')
      const opened = () => {
        this.element.classList.remove('is-opening')
        resolve()
        this.element.removeEventListener('transitionend', opened, false)
      }
      this.element.addEventListener('transitionend', opened, false)
    })
  }

  /**
   * Closing animation to hide promoted price
   */
  close () {
    return new Promise(resolve => {
      if (!this.isOpened) return resolve()
      this.isOpened = false
      this.element.classList.add('is-closing')
      forceRepaint(this.element)
      this.element.classList.remove('in')
      const closed = () => {
        this.element.classList.remove('is-closing')
        resolve()
        this.element.removeEventListener('transitionend', closed, false)
      }
      this.element.addEventListener('transitionend', closed, false)
    })
  }

  /**
   * Builds a PackageBlockData model from raw API data
   *
   * @param {Object} promotedPriceResponse - The original data retrieved from api
   *
   * @returns {PromotedPriceData}
   */
  _getPromotedPriceData (promotedPriceResponse) {
    return {
      price: getPriceData(promotedPriceResponse, this.locales, this.modalId, this.extraParams.contextitemid),
      packageBlock: getPackageBlockData(promotedPriceResponse),
      skiPassPublicPriceText: getSkiPassPublicPriceText(promotedPriceResponse),
      buttonText: this.locales.textButton,
      yieldCampaignLogo:
        promotedPriceResponse.yieldCampaignLogo && promotedPriceResponse.yieldCampaignLogo.src
          ? { src: promotedPriceResponse.yieldCampaignLogo.src }
          : undefined
    }
  }

  /**
   * Gets extra params through hidden inputs on DOM
   *
   * @returns {Object|undefined}
   */
  _getExtraParamsFromDom () {
    const extraParamsElements = this.element.querySelectorAll(widgetQueries.extraParams)
    const extraParams = Object.values(extraParamsElements)
    return extraParams
      ? extraParams.reduce((obj, el) => {
        obj[el.name] = el.value
        return obj
      }, {})
      : undefined
  }

  /**
   * Gets dataSource through DOM element if any
   *
   * @returns {Object|undefined}
   */
  _getDataSourceFromDom () {
    const dataSourceElement = this.element.querySelector(widgetQueries.dataSource)
    if (!dataSourceElement) return undefined
    try {
      return JSON.parse(dataSourceElement.innerHTML)
    } catch (e) {
      return undefined
    }
  }

  _buildEvents () {
    this._domEvents = [
      [this.element.querySelector(widgetQueries.button), { click: ev => this._handlePromotedPriceButtonClick(ev) }]
    ]
  }

  _handlePromotedPriceButtonClick (ev) {
    this.events.emit('promotedPriceClicked', this.promotedPriceData?.rawData)
  }

  /**
   * Return a locale strings object
   */
  _getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.element.id}"]`)
    if (!customLocaleElement) return COMPONENT_LOCALES

    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return {
      ...COMPONENT_LOCALES,
      ...customLocaleData
    }
  }

  _getOriginalDepartureDate () {
    return this.originalDepartureDate
  }

  _showStickyPromotedPrice (context) {
    this.isPricesTab = context.isPricesTab

    if (this.isPricesTab) {
      this.elements.stickyContainer?.classList?.add('is-hidden')
    } else {
      this.elements.stickyContainer?.classList?.remove('is-hidden')
    }
  }

  addBannerifDiscount (d, locales) {
    const element = document.querySelector('.discount-banner')
    if ((d.price?.discountPercentage || d.yieldCampaignCode) && locales.discountBannerMessage) {
      if (element) return
      const banner = elementFromString(`<div class="discount-banner">${locales.discountBannerMessage}</div>`)
      const container = document.querySelector('.t-accommodation__main-top-b')
      const carrouselElement = container?.querySelector('.fr-acco-carousel')
      if (carrouselElement) {
        container.insertBefore(banner, carrouselElement.nextSibling)
      } else {
        // If there is no carousel, we need to check if there is a react gallery
        const galleryReactElement = container?.querySelector('.accommodation-carousel')
        banner.classList.add('discount-banner--react-compatible')
        if (galleryReactElement) {
          container.insertBefore(banner, galleryReactElement.nextSibling)
        }
      }
    } else {
      if (element) {
        element.remove()
      }
    }
  }
}

registerWidget(PromotedPrice, widgetApi)
