import { isValidDateString, formatDate } from '../../../js/helpers/dates'
import { isEmptyObject } from '../../../js/helpers/object'
import { carRentalMessageTypes } from './config'
import PriceFormatter from '../../../js/helpers/price-formatter'

export default class BookingCarRentalDataMapper {
  mapService (apiService, configurations, options) {
    const widgetService = (!isEmptyObject(apiService) && !isEmptyObject(configurations))
      ? {
          ...this._mapDefaultTexts(apiService, configurations),
          carsLists: this._mapCarsLists(apiService, configurations, options),
          editDetailsInformation: this._mapEditDetailsInformation(apiService, configurations, options),
          isCarSelectedRequired: apiService.includedCarOutOfStock || false,
          messages: this._mapCarRentalMessages(apiService, configurations),
          participants: apiService.participants ? apiService.participants.map(participant => this._mapParticipant(participant)) : [],
          usps: apiService.usps ? this._mapUspInfo(apiService.usps) : null
        }
      : null
    const selectedOptionsLength = (apiService.options && apiService.options.filter(o => o.isSelected).length) || 0
    const addExtraCarsConfiguration = this._mapAddExtraCarsConfiguration(configurations, selectedOptionsLength, apiService.serviceBookingLimit)

    return widgetService ? { ...widgetService, ...addExtraCarsConfiguration } : null
  }

  _mapDefaultTexts (apiService, configurations) {
    return {
      collapseButtonTexts: {
        viewMoreButton: (configurations.buttonTexts && configurations.buttonTexts.showMoreItemsButtonText) || '',
        viewLessButton: (configurations.buttonTexts && configurations.buttonTexts.showLessItemsButtonText) || '',
        editCarButton: (configurations.buttonTexts && configurations.buttonTexts.editButtonText) || '',
        removeCarButton: (configurations.buttonTexts && configurations.buttonTexts.removeButtonText) || '',
        upgradeCarButton: (configurations.buttonTexts && configurations.buttonTexts.upgradeButtonText) || ''
      },
      driverLabel: (configurations.checkoutTexts && configurations.checkoutTexts.driverLabelText) || '',
      optionalEquipmentText: (configurations.carTexts && configurations.carTexts.optionalEquipmentText) || '',
      checkoutAdvice: (configurations.checkoutTexts && configurations.checkoutTexts.checkoutAdviceText) || '',
      selectedEquipmentText: (configurations.carTexts && configurations.carTexts.selectedEquipmentText) || '',
      conditions: configurations && configurations.conditionGroup ? this._mapConditions(configurations.conditionGroup, apiService) : null
    }
  }

  mapDefaultChoiceListCar (apiService, configurations, options) {
    const bookingParticipantIds = apiService.participants && apiService.participants.map(participant => participant.id)
    const availableCars = this._availableCars(apiService.options)
    const filteredCars = this._filterCarsBasedOnDisabledParticipants(bookingParticipantIds, availableCars)
    const notSelectedCars = filteredCars.filter(car => !car.isSelected)

    const defaultChoiceListCar = (!isEmptyObject(apiService) && !isEmptyObject(configurations))
      ? {
          ...this._mapChoiceListCars({ apiService, bookingParticipantIds, notSelectedCars, configurations, options }),
          ...this._mapDefaultTexts(apiService, configurations)
        }
      : null
    return defaultChoiceListCar
  }

  _mapAddExtraCarsConfiguration (configurations, selectedOptionsLength, serviceBookingLimit = 1) {
    if (!configurations.addExtraCarsConfiguration) { return }
    return {
      addExtraCarsInformation: {
        ...configurations.addExtraCarsConfiguration,
        addExtraCarDescription: configurations.addExtraCarsConfiguration.addExtraCarDescription && configurations.addExtraCarsConfiguration.addExtraCarDescription.replace('{book_cars_limit}', (serviceBookingLimit >= 1 ? serviceBookingLimit : '')),
        showAddExtraCarsBlock: configurations.addExtraCarsConfiguration.addExtraCarEnabled && selectedOptionsLength > 0 && serviceBookingLimit > 0 && serviceBookingLimit > selectedOptionsLength
      }
    }
  }

  _mapNumberOfOptionsToBeShown (serviceOptions = [], maxNumberOfCarsToBeShown) {
    const serviceOptionsSelectedLength = serviceOptions ? serviceOptions.length : 0
    return serviceOptionsSelectedLength > 0 ? serviceOptionsSelectedLength : maxNumberOfCarsToBeShown
  }

  _mapCarsLists (apiService, configurations, options) {
    const bookingParticipantIds = apiService.participants && apiService.participants.map(participant => participant.id)
    const availableCars = this._availableCars(apiService.options)
    const filteredCars = this._filterCarsBasedOnDisabledParticipants(bookingParticipantIds, availableCars)
    const selectedCars = filteredCars.filter(car => car.isSelected)
    const notSelectedCars = filteredCars.filter(car => !car.isSelected)

    const carsLists = [...Array(selectedCars.length || 1).fill().map((x, index) => {
      const currentSelectedCars = selectedCars && selectedCars.length > 0 ? [...Array(selectedCars.shift())] : []
      const selectedCarPrice = currentSelectedCars.length > 0 && currentSelectedCars[0] ? currentSelectedCars[0].price : 0
      return this._mapChoiceListCars({ apiService, bookingParticipantIds, currentSelectedCars, notSelectedCars, configurations, index, options, selectedCarPrice })
    })]

    return carsLists
  }

  _getCarListId (componentId, index) {
    return `${componentId}-list-id-${this._generatePartialId(index)}`
  }

  _generatePartialId (num) {
    return (num !== '') ? Number(num + 1) : 'new'
  }

  _mapChoiceListCars (data) {
    const { apiService, currentSelectedCars = [], configurations, index = '', options } = data
    return {
      carListInformation: {
        listId: this._getCarListId(apiService.componentId, index)
      },
      ...configurations.showCarsInOneRow && { showCarsInOneRow: configurations.showCarsInOneRow },
      ...configurations.isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow && { isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow: configurations.isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow },
      collapseListInformation: {
        collapseId: `${apiService.componentId}-collapse-list-${this._generatePartialId(index)}`,
        ...configurations.checkoutTexts && configurations.checkoutTexts.collapseListInformationTitle && { title: (configurations?.checkoutTexts?.collapseListInformationTitle) || '' },
        maxItems: this._mapNumberOfOptionsToBeShown(currentSelectedCars, options.maxNumberOfCarsToBeShown),
        showCollapseTriggersSection: (currentSelectedCars.length === 0) || (currentSelectedCars.length > 0 && !configurations.upgradeCarFeatureEnabled)
      },
      choiceListSelectedCarInformation: {
        method: 'single',
        variant: 'boxed',
        showInSlider: false, // important this one to be false for visualization
        hasRichOptions: true,
        id: apiService.componentId + `_choice-list-selected-cars-${this._generatePartialId(index)}`,
        attributes: {
          'data-message-required': configurations.checkoutTexts ? configurations.checkoutTexts.requiredMessageWhenIncludedCarsAreNotAvailable : '',
          'data-w-booking-car-rental__choice-list': ''
        },
        items: currentSelectedCars.length > 0 ? this._mapAvailableSelectedCars(data) : [],
        required: apiService.includedCarOutOfStock
      },
      choiceListInformation: {
        method: 'single',
        variant: 'boxed',
        showInSlider: configurations.showCarsInOneRow,
        hasRichOptions: true,
        id: apiService.componentId + `_choice-list-cars-${this._generatePartialId(index)}`,
        attributes: {
          'data-message-required': configurations.checkoutTexts ? configurations.checkoutTexts.requiredMessageWhenIncludedCarsAreNotAvailable : '',
          'data-w-booking-car-rental__choice-list': ''
        },
        items: apiService.options.length > 0 ? this._mapAvailableCars(data) : [],
        required: apiService.includedCarOutOfStock
      }
    }
  }

  _mapAvailableSelectedCars (data) {
    const { apiService, bookingParticipantIds, currentSelectedCars = [], configurations, selectedCarPrice = 0 } = data
    const anyCarSelected = (currentSelectedCars.length > 0)
    if (!currentSelectedCars.length > 0) return

    return [...currentSelectedCars].map(availableCar => this._mapAvailableCar(availableCar, apiService, configurations, bookingParticipantIds, selectedCarPrice, anyCarSelected))
  }

  _mapAvailableCars (data) {
    const { apiService, bookingParticipantIds, currentSelectedCars = [], notSelectedCars, configurations, selectedCarPrice = 0 } = data
    const anyCarSelected = (currentSelectedCars.length > 0)
    let filteredNotSelectedCars = notSelectedCars
    let carsToBeMapped = []
    if (configurations && !configurations.showCarsInOneRow) {
      if (currentSelectedCars && currentSelectedCars.length > 0) {
        currentSelectedCars.forEach(selectedCar => {
          const notSelectedCar = notSelectedCars.find(car => car.carClass === selectedCar?.carClass)
          if (notSelectedCar) {
            filteredNotSelectedCars = notSelectedCars.filter(car => car.id !== notSelectedCar.id)
          }
        })
      }
      carsToBeMapped = [...currentSelectedCars, ...filteredNotSelectedCars]
    } else {
      carsToBeMapped = [...filteredNotSelectedCars]
    }

    return carsToBeMapped.map(availableCar => this._mapAvailableCar(availableCar, apiService, configurations, bookingParticipantIds, selectedCarPrice, anyCarSelected))
  }

  _filterCarsBasedOnDisabledParticipants (bookingParticipantIds, cars) {
    return cars.filter(car => this._checkIfCarIsBookable(car, bookingParticipantIds))
  }

  _checkIfCarIsEditable (car, participantIds = []) {
    let carsIsEditable = true
    if (car.disabledParticipants && car.disabledParticipants.length > 0) {
      carsIsEditable = car.isSelected && !this._checkIfDisabledParticipantsMatch(car.disabledParticipants, participantIds)
    }
    const isSelfEditable = Object.prototype.hasOwnProperty.call(car, 'isSelfEditable') ? car.isSelfEditable : true
    if (!isSelfEditable) carsIsEditable = false
    return carsIsEditable
  }

  _checkIfCarCanBeUpgraded (apiAvailableCar, configurations) {
    if (!configurations.upgradeCarFeatureEnabled) { return false }
    return Object.prototype.hasOwnProperty.call(apiAvailableCar, 'canBeUpgraded') ? apiAvailableCar.canBeUpgraded : true
  }

  _checkIfCarIsBookable (car, participantIds) {
    let isBookable = true

    if (!car.isSelected && car.disabledParticipants && car.disabledParticipants.length > 0) {
      isBookable = !this._checkIfDisabledParticipantsMatch(car.disabledParticipants, participantIds)
    }

    return isBookable
  }

  _checkIfDisabledParticipantsMatch (participantsFromOption, participantIds) {
    return Array.isArray(participantsFromOption) &&
      Array.isArray(participantIds) &&
      participantsFromOption.length === participantIds.length &&
      participantsFromOption.every((val, index) => val === participantIds[index])
  }

  _availableCars (options) {
    const uniqueAvailableCars = options.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
    return uniqueAvailableCars
  }

  _mapCar (apiCar, configurations, selectedCarPrice, anyCarSelected) {
    const widgetCar = {
      code: apiCar.code,
      name: apiCar.carModel || apiCar.displayText,
      type: apiCar.carClass,
      image: apiCar.imagePath ? apiCar.imagePath.large : '',
      participants: apiCar.participants,
      label: apiCar.availableOnRequest ? configurations.carTexts.onRequestLabelText : '',
      price: this._mapCarPrice(apiCar, configurations, selectedCarPrice, anyCarSelected),
      features: this._mapCarFeatures(apiCar, configurations),
      locationCode: apiCar.locationCode,
      conditions: apiCar.conditionsUrl ? this._mapCarConditions(apiCar.conditionsUrl, configurations) : null
    }
    return widgetCar
  }

  _mapCarConditions (conditionsUrl, configurations) {
    if (!conditionsUrl) { return }

    return {
      icon: 'file',
      text: configurations.carTexts.carConditionsLabelText || '',
      link: {
        href: conditionsUrl,
        target: '_blank',
        title: configurations.carTexts.carConditionsLabelText || ''
      }
    }
  }

  _mapCarPrice (apiCar, configurations, selectedCarPrice, anyCarSelected) {
    const priceSettings = this._mapPriceSettings(configurations)
    const carPrice = (apiCar.isSelected && !apiCar.isIncluded) ? apiCar.price : this._getPrice(apiCar, configurations, selectedCarPrice)
    const carPriceFormatted = PriceFormatter.toFormattedText(Math.abs(carPrice), {
      numberOfDecimals: configurations?.priceConfiguration?.numberOfDecimals,
      decimalSeparator: configurations?.priceConfiguration?.decimalSeparator
    })

    return {
      ...priceSettings,
      ...{
        value: carPriceFormatted,
        legend: apiCar.location,
        icon: 'airplane',
        publicPrice: apiCar.pricePerDay,
        pricePrefix: anyCarSelected && !apiCar.isSelected ? this._getPricePrefix(apiCar, carPrice) : ''
      }
    }
  }

  _getPrice (apiCar, configurations, selectedCarPrice) {
    if (!configurations.priceConfiguration) {
      return (apiCar.isIncluded && apiCar.price > 0) ? `+${apiCar.priceAmountValue}` : apiCar.priceAmountValue
    }

    return this._getOptionAdditionalPrice(apiCar, selectedCarPrice)
  }

  _getOptionAdditionalPrice (apiOption, selectedOptionPrice) {
    const optionPrice = apiOption.price || 0
    const optionCalculatedPrice = selectedOptionPrice ? optionPrice - selectedOptionPrice : optionPrice
    return optionCalculatedPrice
  }

  _getPricePrefix (apiCar, carPrice) {
    const optionPriceSymbol = carPrice > 0 ? '+' : carPrice < 0 ? '-' : ''
    return this._checkIfOptionServiceTypeIsPerParticipant(apiCar) ? optionPriceSymbol : '-'
  }

  _checkIfOptionServiceTypeIsPerParticipant (apiOption = null) {
    return apiOption && !apiOption.occupancy
  }

  _mapCarFeatures (apiCar, configurations) {
    return {
      passengers: `${apiCar.passengers}`,
      luggage: `${apiCar.luggage}`,
      doors: `${apiCar.doors}`,
      airConditioner: apiCar.hasAirconditioned
        ? configurations.carTexts.characteristicAirYesText
        : configurations.carTexts.characteristicAirNoText,
      gearShift: apiCar.hasAutomaticGear
        ? configurations.carTexts.characteristicGearAutomaticText
        : configurations.carTexts.characteristicGearManualText
    }
  }

  _mapCarAsOption (apiOption, isRequired = false) {
    const option = {
      id: apiOption.code,
      name: apiOption.code,
      value: apiOption.code,
      checked: apiOption.isSelected,
      isSelected: apiOption.isSelected,
      required: isRequired,
      extraClassesForRichOption: 'w-booking-car-rental__option',
      extraClasses: `${apiOption.isSelected ? 'w-booking-car-rental__option_radio_hide' : ''}`,
      attributes: {
        'data-w-booking-car-rental__option': '',
        'data-w-booking-car-rental__location': apiOption.location,
        'data-w-booking-car-rental__location-code': apiOption.locationCode,
        'data-w-booking-car-rental__item-code': apiOption.code,
        'data-w-booking-car-rental__item-type': apiOption.carClass,
        'data-w-booking-car-rental__item-participants': apiOption.participants
      }
    }
    return option
  }

  _mapAvailableCar (apiAvailableCar, apiService, configurations, bookingParticipantIds, selectedCarPrice, anyCarSelected) {
    const widgetOption = this._mapCarAsOption(apiAvailableCar, apiService.includedCarOutOfStock)
    const widgetBaseCar = this._mapCar(apiAvailableCar, configurations, selectedCarPrice, anyCarSelected)
    const widgetAvailableCar = {
      hasEquipments: !!(apiAvailableCar.equipments && apiAvailableCar.equipments.length > 0),
      equipments: this._mapEquipments(apiAvailableCar)
    }
    const carIsEditable = this._checkIfCarIsEditable(apiAvailableCar, bookingParticipantIds)
    const extraAvailableCarsToBeUpgraded = apiService.options.filter(option => !option.isSelected && option.code !== apiAvailableCar.code)
    const carCanBeUpgraded = extraAvailableCarsToBeUpgraded.length > 1 ? this._checkIfCarCanBeUpgraded(apiAvailableCar, configurations) : false
    const widgetSelectedCar = apiAvailableCar.isSelected ? this._mapSelectedCar(apiAvailableCar, apiService.participants, carIsEditable, carCanBeUpgraded) : {}

    return { ...widgetOption, ...widgetSelectedCar, car: { ...widgetAvailableCar, ...widgetBaseCar } }
  }

  _mapSelectedCar (apiAvailableCar, participants, carIsEditable, carCanBeUpgraded) {
    const driver = apiAvailableCar.driverId ? participants.find(participant => participant.id === apiAvailableCar.driverId) : participants[0]

    return {
      isIncluded: apiAvailableCar.isIncluded,
      driver: {
        participantId: driver.id,
        name: driver.name || driver.alternativeName,
        birthdate: driver.birthdate,
        formattedBirthdate: driver.birthdate,
        hasCheckedCreditCard: !!((driver.id && driver.birthdate))
      },
      selectedEquipments: apiAvailableCar.selectedEquipments || null,
      isCarEditable: carIsEditable,
      canBeUpgraded: carCanBeUpgraded,
      isCancellable: Object.prototype.hasOwnProperty.call(apiAvailableCar, 'isCancellable') ? apiAvailableCar.isCancellable : true
    }
  }

  _mapParticipant (apiParticipant) {
    const widgetParticipant = {
      id: apiParticipant.id.toString(),
      name: apiParticipant.name || apiParticipant.alternativeName,
      birthdate: isValidDateString(apiParticipant.birthdate) ? apiParticipant.birthdate : '',
      ageCategory: apiParticipant.ageCategory
    }
    return widgetParticipant
  }

  _mapEditDetailsInformation (apiService, configurations, options) {
    return {
      collapseId: `${apiService.componentId}-edit-details`,
      cancelButton: configurations.buttonTexts ? configurations.buttonTexts.cancelButtonText : '',
      saveButton: configurations.buttonTexts ? configurations.buttonTexts.saveButtonText : '',
      addButton: configurations.buttonTexts ? (configurations.buttonTexts.addButtonText || configurations.buttonTexts.saveButtonText) : '',
      origin: {
        label: configurations.checkoutTexts ? configurations.checkoutTexts.originLabelText : ''
      },
      driver: {
        label: configurations.checkoutTexts ? configurations.checkoutTexts.driverLabelText : '',
        placeholder: configurations.checkoutTexts ? configurations.checkoutTexts.driverPlaceholderText : '',
        messageRequired: configurations.checkoutTexts ? configurations.checkoutTexts.driverMessageRequiredText : ''
      },
      birthdate: {
        label: configurations.checkoutTexts ? configurations.checkoutTexts.driverBirthdateLabelText : '',
        disabled: !configurations.editDriverBirthdateEditable,
        departureDate: apiService.departureDate,
        minDateVisible: this._calculateMinDate(apiService.departureDate, options.minimumAge),
        dateSelectorPlaceholders: {
          day: configurations.checkoutTexts ? configurations.checkoutTexts.driverBirthdateSelectorPlaceholderDay : '',
          month: configurations.checkoutTexts ? configurations.checkoutTexts.driverBirthdateSelectorPlaceholderMonth : '',
          year: configurations.checkoutTexts ? configurations.checkoutTexts.driverBirthdateSelectorPlaceholderYear : ''
        },
        messageRequired: configurations.checkoutTexts ? configurations.checkoutTexts.driverBirthdateSelectorMessageRequired : '',
        messageInvalid: configurations.checkoutTexts ? configurations.checkoutTexts.driverBirthdateSelectorMessageInvalid : '',
        messageOutOfRange: configurations.checkoutTexts ? configurations.checkoutTexts.driverBirthdateSelectorMessageOutOfRange : ''
      },
      checkoutAdvice: configurations.checkoutTexts ? configurations.checkoutTexts.checkoutAdviceText : '',
      equipmentsLabel: configurations.checkoutTexts ? configurations.checkoutTexts.equipmentsLabelText : '',
      equipmentsIntroText: configurations.checkoutTexts && configurations.checkoutTexts.equipmentsIntroText ? this._mapEquipmentsIntroText(configurations.checkoutTexts.equipmentsIntroText, options.maxNumberOfEquipmentsToBeSelected) : '',
      ageWarning: this._mapAgeWarningFromConfig(configurations)
    }
  }

  _mapEquipmentsIntroText (equipmentsIntroText, maxNumberOfEquipments = undefined) {
    let equipmentsIntroTextReplaced = ''
    if (maxNumberOfEquipments !== undefined && equipmentsIntroText) {
      const maxNumberOfEquipmentsToBeReplaced = maxNumberOfEquipments >= 0 ? maxNumberOfEquipments : ''
      equipmentsIntroTextReplaced = equipmentsIntroText && equipmentsIntroText.replace('{max_equipments}', maxNumberOfEquipmentsToBeReplaced).replace('{MAX_EQUIPMENTS}', maxNumberOfEquipmentsToBeReplaced)
    }

    return equipmentsIntroTextReplaced
  }

  _mapEquipments (apiAvailableCar) {
    let widgetEquipments = []
    if (apiAvailableCar.equipments) {
      const selectedEquipmentIds = apiAvailableCar.selectedEquipments && apiAvailableCar.selectedEquipments.map(selectedEquipment => selectedEquipment.id)
      widgetEquipments = apiAvailableCar.equipments.map(equipment => this._mapEquipment(equipment, selectedEquipmentIds))
    }
    return widgetEquipments
  }

  _mapEquipment (apiEquipment, selectedEquipmentIds) {
    const equipmentSelected = selectedEquipmentIds ? selectedEquipmentIds.includes(apiEquipment.id) : false
    const widgetEquipment = {
      id: apiEquipment.id,
      text: apiEquipment.text,
      selected: equipmentSelected
    }
    return widgetEquipment
  }

  _mapPriceSettings (configurations) {
    const currencyPosition = configurations.priceConfiguration && configurations.priceConfiguration.isCurrencyAtStart ? 'before' : 'after'
    const currencySymbol = configurations.priceConfiguration && configurations.priceConfiguration.currencySymbol ? configurations.priceConfiguration.currencySymbol : ''

    return {
      size: '',
      currencyPosition,
      currency: currencySymbol
    }
  }

  _mapAgeWarningFromConfig (configurations) {
    if (!configurations.ageWarningConfiguration) { return }
    return {
      age: Number(configurations.ageWarningConfiguration.ageWarningAge),
      text: configurations.ageWarningConfiguration.ageWarningText,
      enabled: configurations.ageWarningConfiguration.ageWarningAge && configurations.ageWarningConfiguration.ageWarningText
    }
  }

  _calculateMinDate (departureDate, minimumAgeToDrive) {
    const minDate = departureDate === '' ? new Date() : new Date(departureDate)
    minimumAgeToDrive ? minDate.setFullYear(minDate.getFullYear() - minimumAgeToDrive) : minDate.getFullYear()
    return formatDate(minDate)
  }

  _mapUspInfo (apiUsps) {
    return { items: apiUsps.map(usp => this._mapUsp(usp)) }
  }

  _mapUsp (apiUsp) {
    const hasLink = apiUsp.link && apiUsp.link !== null && apiUsp.link !== ''
    let widgetUsp = {
      text: apiUsp.text
    }

    widgetUsp = hasLink ? { ...widgetUsp, isIconHidden: true, link: { href: apiUsp.link, target: '_blank', title: apiUsp.linkAlternateText } } : widgetUsp

    return widgetUsp
  }

  _mapCarRentalMessages (apiService, configurations) {
    let widgetMessage = {}

    if (configurations.checkoutTexts && (apiService.isOpenJawPackage || apiService.includedCarOutOfStock || apiService.includedCarPriceChanged)) {
      const openJawPackageMessage = this._mapOpenJawPackageMessage(apiService, configurations)
      const includedCarsNoAvailabilityMessage = this._mapCarRentalMessage(apiService.includedCarOutOfStock, configurations.checkoutTexts.includedCarsNoAvailabilityMessageText, configurations.checkoutTexts.includedCarsNoAvailabilityMessageTitle) || null
      const priceIncreaseWarningMessage = this._mapCarRentalMessage(apiService.includedCarPriceChanged, configurations.checkoutTexts.includedCarPriceChangedMessageText, configurations.checkoutTexts.includedCarPriceChangedMessageTitle) || null
      const messagesToBeShown = []

      if (openJawPackageMessage) { messagesToBeShown.push(...openJawPackageMessage) }
      if (includedCarsNoAvailabilityMessage) { messagesToBeShown.push(includedCarsNoAvailabilityMessage) }
      if (priceIncreaseWarningMessage) { messagesToBeShown.push(priceIncreaseWarningMessage) }

      widgetMessage = { messages: messagesToBeShown }
    }
    return (!isEmptyObject(widgetMessage)) ? widgetMessage : null
  }

  _mapOpenJawPackageMessage (apiService, configurations) {
    if (!apiService.isOpenJawPackage) { return null }
    const priceConfig = configurations && configurations.priceConfiguration ? configurations.priceConfiguration : apiService.priceConfiguration

    const selectedCars = apiService.options.filter(o => o.isSelected)
    let openJawMessages = []
    selectedCars.forEach(car => {
      const openJawAmountFormatted = Object.prototype.hasOwnProperty.call(car, 'openJawFeeAmount') ? PriceFormatter.toFormattedText(car.openJawFeeAmount, priceConfig) : ''
      if (Object.prototype.hasOwnProperty.call(car, 'openJawFeeAmount') && car.openJawFeeAmount === 0) { return }
      let textReplaced = (configurations.checkoutTexts.openJawFeeMessageText && Object.prototype.hasOwnProperty.call(car, 'openJawFeeAmount')) ? configurations.checkoutTexts.openJawFeeMessageText.replace('{open_jaw_fee_amount}', openJawAmountFormatted) : (configurations.checkoutTexts.openJawFeeMessageText || '')
      if (selectedCars.length > 1) { textReplaced = `${car.carModel}: ${textReplaced}` }
      const message = this._mapCarRentalMessage(apiService.isOpenJawPackage, textReplaced, configurations.checkoutTexts.openJawFeeMessageTitle, carRentalMessageTypes.info) || null
      openJawMessages = [...openJawMessages, message].filter(m => m !== null)
    })

    return openJawMessages
  }

  _mapCarRentalMessage (isMessageNeededToBeShown, text = '', title = '', type = carRentalMessageTypes.warning) {
    let message = {}
    if (isMessageNeededToBeShown && text) {
      message = {
        title,
        texts: [text],
        type
      }
    }
    if (!isEmptyObject(message)) {
      return message
    }
  }

  _mapConditions (conditionGroup, apiService) {
    const isRequired = conditionGroup.isRequired && apiService.options.some(option => option.isSelected)
    const conditions = {
      choiceListInfo: {
        method: conditionGroup.method.toLowerCase(),
        id: apiService.componentId + '_conditions',
        name: apiService.componentId + '_conditions',
        items: conditionGroup.options.map(option => ({ ...option, required: (isRequired && option.isRequired), checked: apiService.areConditionsAccepted })),
        required: isRequired,
        disabled: false,
        attributes: {
          'data-message-required': conditionGroup.messages.map(message => message).join(','),
          'data-w-booking-car-rental__choice-list--conditions': ''
        }
      }
    }
    return conditions
  }
}
