import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { BookingChoiceListTemplate } from './w-booking-choice-list.template'
import { BookingConditionsTemplate } from './w-booking-conditions.template'
import { BookingMessageTemplate } from '../booking-message/w-booking-message.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import { bookingStepsEvents, bookingItemEvents, bookingChoiceListEvents } from '../../../js/document/event-types'
import webStorage from '../../../js/document/web-storage'
import Component from '../../../js/core/component/component'
import BookingChoiceListDataMapper from './data-mapper'
import registeredEvents from '../../../js/helpers/registered-events'
import { operationTypeEnum } from './enums'
import { cleanUpSpecialCharacters } from '../../../js/helpers/string'

const widgetApi = 'w-booking-choice-list'

const widgetQueries = {
  configurationElement: `[data-${widgetApi}__configuration]`,
  choiceLists: `[data-${widgetApi}__choice-list]`,
  choiceListApi: 'c-choice-list',
  trackingEventAttr: `data-${widgetApi}__tracking-event-name`,
  bookingMessagesElement: `[data-${widgetApi}__messages]`,
  trackAttr: `data-${widgetApi}__track`,
  conditionsElement: `[data-${widgetApi}__conditions-container]`,
  conditionsElementChoiceList: `[data-${widgetApi}__choice-list--conditions]`,
  appliesToAllParticipantsElement: `[data-${widgetApi}__applies-to-all-participants-container]`,
  appliesToAllParticipantsElementSwitch: `[data-${widgetApi}__switch--applies-to-all-participants]`,
  bookingChoiceListItem: `[data-${widgetApi}__item]`,
  bookingChoiceListHeadingRoomTitle: `[data-${widgetApi}__heading-room-title]`
}

const attr = {
  conditionsUrl: 'conditionsUrl',
  appliesToAllParticipantsUrl: 'appliesToAllParticipantsUrl'
}

const configOptions = {
  conditionGroup: 'conditionGroup',
  showMessagesOnTop: 'showMessagesOnTop',
  showAppliesToAllParticipants: 'showAppliesToAllParticipants',
  appliesToAllParticipantEnabledByDefault: 'appliesToAllParticipantEnabledByDefault',
  appliesToAllParticipantsLabel: 'appliesToAllParticipantsLabel',
  ageCategoriesTexts: 'ageCategoriesTexts',
  isLabelPricePerAgeCategoryAtStart: 'isLabelPricePerAgeCategoryAtStart'
}

const classNames = {
  hidden: 'is-hidden',
  hideLabel: 'label-is-hidden'
}
/**
 * BookingChoiceList widget
 */
export default class BookingChoiceList extends BookingBox {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   * @param {Object} [options={}]   - Options
   */
  constructor (element, options = {}) {
    super(element)
    this.element = element
    this.dataMapper = new BookingChoiceListDataMapper()
    this.choiceListApis = null
    this.firstLoad = true
    this.trackingEvent = this.element.getAttribute(widgetQueries.trackingEventAttr)
    this.track = this.element.getAttribute(widgetQueries.trackAttr)
    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement)
    this.conditionsUrl = this.element.dataset[attr.conditionsUrl]
    this.appliesToAllParticipantsUrl = this.element.dataset[attr.appliesToAllParticipantsUrl]
    this.choosePerPersonIsChecked = false
    this.choosePerPersonIsAvailable = false
    this.draftId = null
    this.serviceName = null
    this.disabledValues = {}

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.element.attributes[widgetQueries.trackAttr].value }
    })

    // Attach events to the choice list elements in the widget
    this._attachEvents()
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this.draftId = this.data.draftId
    this._updateWidgetData()
    this.firstLoad = false
    return super.handleFetched(data.response)
  }

  getServiceName () {
    return this.trackingEvent || this.serviceName || ''
  }

  _conditionsClicked (options) {
    const eventArgs = {
      method: operationTypeEnum.patchOperation,
      url: this.conditionsUrl,
      body: {
        conditions: this._mapConditionOptions(options)
      }
    }
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _appliesToAllParticipantsClicked (event) {
    const inputAllParticipants = event.currentTarget.querySelector('input')
    this.choosePerPersonIsChecked = !inputAllParticipants.checked
    const serviceId = this._getServiceIdSelectedAppliesToAllParticipants()
    this._saveChoosePerPersonToSessionStorage(this.choosePerPersonIsChecked)
    this._emitChoosePerPersonEvent(this.choosePerPersonIsChecked)
    inputAllParticipants.checked = this.choosePerPersonIsChecked
    if (!this.choosePerPersonIsChecked && serviceId) {
      const eventArgs = {
        method: operationTypeEnum.patchOperation,
        url: this.appliesToAllParticipantsUrl,
        body: {
          appliesToAllParticipants: !this.choosePerPersonIsChecked,
          serviceId,
          componentId: this.componentId
        },
        componentId: this.componentId
      }
      this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
    } else {
      this._updateWidgetData()
    }
  }

  _emitChoosePerPersonEvent (choosePerPersonIsChecked) {
    const switchEventArgs = {
      choosePerPersonIsChecked,
      serviceName: (this.trackingEvent || this.serviceName || '')
    }

    this.events.emit(bookingChoiceListEvents.CHOOSE_PER_PERSON_SWITCH, switchEventArgs)
  }

  _mapConditionOptions (options) {
    const body = options.reduce(
      (obj, option) => Object.assign(obj, { [option.value]: option.checked }), {})
    return body
  }

  _getServiceIdSelectedAppliesToAllParticipants () {
    const firstParticipantChoiceListApi = this.choiceListApis[0]
    const optionsFirstParticipantChoiceList = firstParticipantChoiceListApi.getProp('options')
    const optionToApplyAllParticipants = optionsFirstParticipantChoiceList.find(opt => opt.checked)
    const serviceId = optionToApplyAllParticipants ? optionToApplyAllParticipants.value : null

    return serviceId
  }

  _clickedOption (options, oldOptions) {
    const selectedOption = options.find(option => option.checked)

    const eventArgs = {
      method: this._method,
      url: this._url,
      body: this._mapSelectedOptionForPatch(selectedOption, oldOptions),
      componentId: this.componentId
    }
    if (this.trackingEvent && selectedOption) {
      eventArgs.rawData = {
        label: this.trackingEvent,
        text: selectedOption.text,
        price: selectedOption.price,
        prices: selectedOption.prices,
        participantIds: selectedOption.participantIds
      }
    }

    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _mapSelectedOptionForPatch (selectedOption, oldOptions) {
    let participantIds = []
    const newServiceValue = selectedOption ? selectedOption.value : ''
    const oldServiceValue = this._getOldServiceValue(oldOptions)
    const oldOption = oldOptions.find(opt => opt.checked)
    const operationType = this._mapOperationType(newServiceValue, oldServiceValue)

    switch (operationType) {
      case operationTypeEnum.addOperation:
      case operationTypeEnum.swapOperation:
        if (!this.choosePerPersonIsAvailable || (this.choosePerPersonIsAvailable && this.choosePerPersonIsChecked)) {
          participantIds = selectedOption.appliesToParticipants.filter(appPart => selectedOption.participantIds.includes(appPart))
        } else {
          participantIds = selectedOption.appliesToParticipants
        }
        break
      case operationTypeEnum.removeOperation:
        participantIds = oldOptions && oldOptions.length > 0 ? oldOptions[0].participantIds : []
        break
      default:
        break
    }

    // Map services
    const newService = {
      code: (selectedOption) ? selectedOption.value : '',
      participants: participantIds,
      startDate: (selectedOption) ? selectedOption.startDate : null,
      endDate: (selectedOption) ? selectedOption.endDate : null
    }

    const oldService = oldOption
      ? {
          code: oldOption.value,
          participants: (!this.choosePerPersonIsChecked) ? participantIds : oldOption.participantIds,
          startDate: oldOption.startDate,
          endDate: oldOption.endDate
        }
      : null

    const body = {
      operationType,
      newService,
      oldService
    }

    return body
  }

  _getOldServiceValue (oldOptions) {
    let oldServiceValue = ''
    if (oldOptions) {
      const oldOption = oldOptions.find(opt => opt.checked)
      if (oldOption) {
        oldServiceValue = oldOption.value
      }
    }
    return oldServiceValue
  }

  _mapOperationType (serviceValue, oldServiceValue) {
    let operationType = operationTypeEnum.addOperation

    if (serviceValue && oldServiceValue) {
      operationType = operationTypeEnum.swapOperation
    } else if (oldServiceValue) {
      operationType = operationTypeEnum.removeOperation
    }

    return operationType
  }

  _attachEvents () {
    const choiceListElements = [...this.element.querySelectorAll(widgetQueries.choiceLists)]
    this.choiceListApis = choiceListElements.map(choiceListElement => choiceListElement[widgetQueries.choiceListApi])
      .filter(api => api)

    this.choiceListApis.forEach(choiceList => {
      choiceList.events.on('changeOptions', this._clickedOption.bind(this))
    })

    this._attachConditionsEvents()
    this._attachAppliesToAllParticipantsEvents()
  }

  _attachConditionsEvents () {
    this.conditionsChoiceListApi = this.conditionsChoiceList ? this.conditionsChoiceList[widgetQueries.choiceListApi] : null
    if (this.conditionsChoiceListApi) {
      this.conditionsChoiceListApi.events.on('changeOptions', (eventArgs) => { this._conditionsClicked(eventArgs) })
    }
  }

  _attachAppliesToAllParticipantsEvents () {
    if (this.appliesToAllParticipantsSwitch) {
      this.appliesToAllParticipantsSwitch.addEventListener('click', (event) => {
        event.preventDefault()
        this._appliesToAllParticipantsClicked(event)
      })
    }
  }

  _init (data) {
    const renderedHtml = BookingChoiceListTemplate(data)
    const newContent = elementFromString(renderedHtml)
    Component.initDocumentComponentsFromAPI(newContent)

    this._render(newContent)
    this._initHtmlElements()
    this._attachEvents()
  }

  _initHtmlElements () {
    this.conditionsElement = this.element.querySelector(widgetQueries.conditionsElement)
    this.conditionsChoiceList = this.conditionsElement ? this.conditionsElement.querySelector(widgetQueries.conditionsElementChoiceList) : null

    this.appliesToAllParticipantsElement = this.element.querySelector(widgetQueries.appliesToAllParticipantsElement)
    this.appliesToAllParticipantsSwitch = this.appliesToAllParticipantsElement ? this.appliesToAllParticipantsElement.querySelector(widgetQueries.appliesToAllParticipantsElementSwitch) : null
  }

  _updateWidgetData () {
    let widgetData

    let apiData = this.data.services && this.data.services.find(service => service.componentId === this.componentId)
    let configurationsSavedSessionStorage = null
    if (this.configurations && this.configurations.showAppliesToAllParticipants) {
      configurationsSavedSessionStorage = this._retrieveChoosePerPersonDataFromStorage()
      if (!configurationsSavedSessionStorage) {
        this._saveChoosePerPersonToSessionStorage(this.configurations.appliesToAllParticipantEnabledByDefault)
      }
    }

    if (apiData) {
      this._updateBookingBoxCollapse(apiData)

      this.serviceName = this._getServiceName(apiData)
      apiData = { ...apiData, ...{ participants: this.data.participants, rooms: this.data.rooms } }

      const appliesToAllParticipantsConfig = configurationsSavedSessionStorage || this._retrieveChoosePerPersonDataFromStorage()
      this.configurations = { ...this.configurations, ...appliesToAllParticipantsConfig }

      widgetData = this.dataMapper.mapService(apiData, this.configurations)

      if (widgetData?.rooms?.length > 0 && widgetData.rooms[0].participants?.length > 0 && widgetData.rooms[0].participants[0].choiceListInfo?.id) {
        const disabledId = widgetData.rooms[0].participants[0].choiceListInfo.id
        this.disabledValues[disabledId] = []

        widgetData.rooms.forEach((room) => {
          room.participants.slice(1).forEach((participant) => {
            const firstParticipant = room.participants[0].choiceListInfo?.items
            if (participant.choiceListInfo?.items?.length > 0) {
              participant.choiceListInfo?.items.forEach((item, index) => {
                if (item.disabled && !this.disabledValues[disabledId].includes(item.value) && !firstParticipant[index].disabled) {
                  this.disabledValues[disabledId].push(item.value)
                }
              })
            }
          })
        })
      }

      if (this.track) {
        widgetData = { ...widgetData, track: this.track }
      }
    }

    if (widgetData) {
      super.showComponent()
      if (this.choiceListApis == null || this.choiceListApis.length === 0) {
        this._init(widgetData)
      }

      const switchInfo = widgetData.choosePerPerson?.switchInfo
      if (widgetData.choosePerPersonSelected && switchInfo && switchInfo.disabled) {
        this._saveChoosePerPersonToSessionStorage(widgetData.choosePerPersonSelected)
      }

      this.choosePerPersonIsChecked = widgetData.choosePerPersonSelected
      this.choosePerPersonIsAvailable = !!widgetData.choosePerPerson
      if (this.appliesToAllParticipantsSwitch) {
        const inputSwitch = this.appliesToAllParticipantsSwitch.querySelector('input')
        if (switchInfo && !switchInfo.disabled && inputSwitch && inputSwitch.disabled) {
          inputSwitch.disabled = false
          this.appliesToAllParticipantsSwitch.classList.remove('is-disabled')
        } else if (switchInfo && switchInfo.disabled && inputSwitch && !inputSwitch.disabled) {
          inputSwitch.disabled = true
          this.appliesToAllParticipantsSwitch.classList.add('is-disabled')
        }
        this._toggleChoiceListElementsVisibility()
      }

      const choiceListInfos = []
      widgetData.rooms.forEach(function (room) {
        room.participants.forEach(function (participant) {
          choiceListInfos.push(participant.choiceListInfo)
        })
      })

      let index = 0
      this.choiceListApis.forEach(choicelistApi => {
        if (!choiceListInfos[index]) {
          choicelistApi.setProp('options', [], { silent: true })
          choicelistApi.setProp('label', '', { silent: true })
        } else {
          choicelistApi.setProp('options', choiceListInfos[index].items, { silent: true })
          if (choiceListInfos[index].label) {
            choicelistApi.setProp('label', choiceListInfos[index].label, { silent: true })
          }
        }
        index++
      })
      if (this.choiceListApis || this.choiceListApis.length !== 0) {
        this._updateConditionsElement(widgetData)
      }
      this._updateMessagesElement(widgetData)
      this._trackInitialValues(apiData)
    } else {
      super.hideComponent()
      this._cleanUpComponentApi()
    }
  }

  _updateBookingBoxCollapse (apiData) {
    const selectedOptions = apiData.options.filter(option => option.isSelected)
    if (selectedOptions.length > 0) {
      const noneOptions = selectedOptions.filter(option => option.isNoneOption)
      if ((noneOptions.length > 0) && (selectedOptions.length === noneOptions.length)) {
        super.noOptionSelectedAndUpdateTitle()
      } else {
        super.setSuccessStateAndUpdateTitle()
      }
    }
  }

  _cleanUpComponentApi () {
    flush(this.bodyElement)
    this.choiceListApis = []
  }

  _getServiceName (data) {
    let serviceName = data.aName
    if (data.options) {
      const groupCodeArrays = data.options.filter(option => option.groupCode || '').map(option => option.groupCode || '')
      const uniqueGroupCodes = [...new Set(groupCodeArrays)]
      serviceName = uniqueGroupCodes.length === 1 ? uniqueGroupCodes[0] : data.aName
    }

    return cleanUpSpecialCharacters(serviceName) || ''
  }

  _toggleChoiceListElementsVisibility () {
    const bookingChoiceListElements = this.element.querySelectorAll(widgetQueries.bookingChoiceListItem)
    const bookingChoiceListHeadingRoomTitles = this.element.querySelectorAll(widgetQueries.bookingChoiceListHeadingRoomTitle)
    if (this.choosePerPersonIsChecked) {
      const appliesToAllParticipantSelected = this.appliesToAllParticipantsSwitch.querySelector('input:checked')
      if (appliesToAllParticipantSelected) {
        this._showAllBookingChoiceListElements(bookingChoiceListElements, bookingChoiceListHeadingRoomTitles)
      }
    } else {
      this._hideBookingChoiceListElements(bookingChoiceListElements, bookingChoiceListHeadingRoomTitles)
    }
  }

  _showAllBookingChoiceListElements (bookingChoiceListElements, bookingChoiceListHeadingRoomTitles) {
    bookingChoiceListElements.forEach((element, index) => {
      element.classList.remove(classNames.hidden)
      element.classList.remove(classNames.hideLabel)
      if (index === 0) {
        if (this.disabledValues[element.children[0].id]) {
          this.disabledValues[element.children[0].id].forEach(value => {
            const option = element.querySelector(`input[value="${value}"]`)
            if (option) {
              option.disabled = false
              option.parentElement.classList.remove('is-disabled')
            }
          })
        }
      }
    })
    bookingChoiceListHeadingRoomTitles.forEach(element => element.classList.remove(classNames.hidden))
  }

  _hideBookingChoiceListElements (bookingChoiceListElements, bookingChoiceListHeadingRoomTitles) {
    bookingChoiceListElements.forEach((element, index) => {
      element.classList.add(classNames.hidden)
      if (index === 0) {
        element.classList.remove(classNames.hidden)
        element.classList.add(classNames.hideLabel)
        if (this.disabledValues[element.children[0].id]) {
          this.disabledValues[element.children[0].id].forEach(value => {
            const option = element.querySelector(`input[value="${value}"]`)
            if (option) {
              option.disabled = true
              option.parentElement.classList.add('is-disabled')
            }
          })
        }
      }
    })
    bookingChoiceListHeadingRoomTitles.forEach(element => element.classList.add(classNames.hidden))
  }

  _updateConditionsElement (widgetData) {
    if (this.conditionsElement && widgetData.conditions) {
      const newContent = elementFromString(BookingConditionsTemplate(widgetData))
      Component.initDocumentComponentsFromAPI(newContent)
      flush(this.conditionsElement)
      this.conditionsElement.appendChild(newContent)
      this._initHtmlElements()
      this._attachConditionsEvents()
    }
  }

  _updateMessagesElement (widgetData) {
    const messagesElement = this.element.querySelector(widgetQueries.bookingMessagesElement)
    if (messagesElement) {
      messagesElement.innerHTML = widgetData.messages ? BookingMessageTemplate(widgetData) : ''
    }
  }

  /**
   * Track the initial values if tracking event is defined
   * (only in the first load)
   */
  _trackInitialValues (apiData) {
    if (!this.firstLoad && !this.trackingEvent) {
      return
    }
    if (apiData.rooms && apiData.rooms.length > 0) {
      this._trackInitialValuesForRooms(apiData)
    } else {
      this._trackInitialValuesForParticipants(apiData)
    }
  }

  _trackInitialValuesForRooms (apiData) {
    if (!apiData.rooms || apiData.rooms.length === 0) { return }
    apiData.rooms.forEach((room) => {
      const filteredParticipants = apiData.participants.filter((participant) => room.participantIds.includes(participant.id))
      this._mapOptionsForParticipants(filteredParticipants, apiData.options)
    })
  }

  _trackInitialValuesForParticipants (apiData) {
    if (!apiData.participants || apiData.participants.length === 0) { return }
    this._mapOptionsForParticipants(apiData.participants, apiData.options)
  }

  _mapOptionsForParticipants (participants, options) {
    if (!participants || participants.length === 0) { return }
    if (!options || options.length === 0) { return }

    participants.forEach((participant) => {
      options.forEach((option) => {
        if (option.isSelected && option.assignedParticipants && option.assignedParticipants.includes(participant.id)) {
          const rawData = {
            label: this.trackingEvent,
            text: option.displayText,
            price: option.price,
            prices: option.prices,
            participantIds: option.assignedParticipants
          }
          this.events.emit(bookingStepsEvents.BOOKING_ITEM_INITIAL_VALUES, rawData)
        }
      })
    })
  }

  _render (el) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(el)
  }

  async handleValidationClient () {
    let validation = true
    const apiWithError = []
    this.choiceListApis && this.choiceListApis.forEach(api => {
      if (api.getProp('method') === 'single' && api.getSelectedValues().length === 0) {
        validation = api.validate().isValid || false
        if (!validation) { apiWithError.push(api) }
      }
    })

    if (!validation) {
      super.openCollapseElement()
      apiWithError.forEach(api => { this._showApiErrors(api) })
    }

    return validation
  }

  async handleConditionsValidation () {
    let validation = true
    let extraInformation = {}
    if (this.conditionsChoiceList) {
      const conditionsChoiceListApi = this.conditionsChoiceList[widgetQueries.choiceListApi]
      if (conditionsChoiceListApi) {
        const performValidation = conditionsChoiceListApi.validate()
        validation = performValidation.isValid
        if (!validation) {
          extraInformation = { serviceName: this.serviceName, fieldType: performValidation.fieldType || '' }
          conditionsChoiceListApi.enableErrors()
          conditionsChoiceListApi.validate()
        }
      }
    }

    return { isValid: validation, ...extraInformation }
  }

  _showApiErrors (api) {
    const options = api.getProp('options')
    const newOptions = []
    options.forEach(option => newOptions.push({ ...option, ...{ state: 'error' } }))
    api.setProp('options', newOptions, { silent: true })
    api.enableErrors()
    api.validate()
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)
      const priceConfigurations = super.readPriceConfigFromConfigurations(configuration)

      return {
        ...priceConfigurations,
        conditionGroup: configuration[configOptions.conditionGroup],
        showMessagesOnTop: configuration[configOptions.showMessagesOnTop],
        showAppliesToAllParticipants: configuration[configOptions.showAppliesToAllParticipants],
        appliesToAllParticipantEnabledByDefault: configuration[configOptions.appliesToAllParticipantEnabledByDefault],
        appliesToAllParticipantsLabel: configuration[configOptions.appliesToAllParticipantsLabel],
        ageCategoriesTexts: configuration[configOptions.ageCategoriesTexts],
        isLabelPricePerAgeCategoryAtStart: configuration[configOptions.isLabelPricePerAgeCategoryAtStart]
      }
    }
  }

  _saveChoosePerPersonToSessionStorage (isChecked = false) {
    const identifier = this._getComponentIdentifier()
    if (identifier) {
      const setChoosePerPersonSelectedData = { choosePerPersonSelected: isChecked }
      webStorage.session.set(identifier, setChoosePerPersonSelectedData)
    }
  }

  _getComponentIdentifier () {
    const identifier = `${this.draftId}-${this.componentId}`
    return identifier !== null ? identifier : undefined
  }

  _retrieveChoosePerPersonDataFromStorage () {
    let choosePerPersonSelectedData
    const identifier = this._getComponentIdentifier()
    if (identifier) {
      choosePerPersonSelectedData = webStorage.session.get(identifier)
    }
    return choosePerPersonSelectedData
  }
}

registerWidget(BookingChoiceList, widgetApi)
